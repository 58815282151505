<template>
    <div class="flex flex-col h-full">
        <div class="flex-auto">
            <template v-if="$store.state.account">
                <v-spa-layout-sidebar-responsive-link
                    section="timetracking"
                    :to="{name: 'account.timetracking.index', params: {account: $store.state.account.uuid}}"
                    icon="far fa-clock"    
                >
                    {{ $t('layout.sidebar.timetracker') }}
                </v-spa-layout-sidebar-responsive-link>
                <v-spa-layout-sidebar-responsive-link
                    section="calendar"
                    :to="{name: 'account.timetracking.calendar', params: {account: $store.state.account.uuid}}"
                    icon="fal fa-calendar-alt"    
                >
                    {{ $t('layout.sidebar.calendar') }}
                </v-spa-layout-sidebar-responsive-link>
                <v-spa-layout-sidebar-responsive-link
                    section="team"
                    :to="{name: 'account.team.index', params: {account: $store.state.account.uuid}}"
                    icon="fal fa-user-friends"    
                >
                    {{ $t('layout.sidebar.team') }}
                </v-spa-layout-sidebar-responsive-link>
                <v-spa-layout-sidebar-responsive-link
                    section="reports"
                    :to="{name: 'account.reports.index', params: {account: $store.state.account.uuid}}"
                    icon="fal fa-chart-bar"    
                >
                    {{ $t('layout.sidebar.reports') }}
                </v-spa-layout-sidebar-responsive-link>
            </template>
        </div>
    </div>
</template>



<script>
    export default {
        components: {},
        computed: {
            
        }
    }
</script>
