<template>
    <div data-component="timetracking-lines">
        <div class="mb-10">
            <div class="flex items-center justify-between mb-4">
                <div class="flex items-center">
                    <template v-if="! loading">
                        <div class="flex items-center -mx-2">
                            <button class="p-2 mr-1 text-gray-800 hover:text-black" @click.prevent="getPreviousWeekActivities">
                                <i class="far fa-chevron-left"></i>
                            </button>
                            <button class="p-2 text-gray-800 hover:text-black" @click.prevent="getNextWeekActivities">
                                <i class="far fa-chevron-right"></i>
                            </button>
                        </div>
                        <div class="flex items-center ml-4">
                            <span class="text-gray-800">{{ dates[0] | moment().startOf('week').format('D MMM') }}</span>
                            <span class="mx-1"> - </span>
                            <span class="text-gray-800">{{ dates[0] | moment().endOf('week').format('D MMM YYYY') }}</span>
                            <span class="ml-4 text-xs text-gray-400">{{ $t('timetracking.week.week_number', {number: $moment(dates[0]).endOf('week').week()}) }}</span>
                        </div>
                    </template>
                </div>
                <div>
                    <slot name="filters"></slot>
                </div>
            </div> 
            <template v-if="! loading">
                <div class="flex items-center justify-around px-8 py-4 bg-white border border-gray-300 rounded">
                    <div v-for="(section, index) in getWeekTotalSections()" :key="`section-${index}`">
                        <span class="block font-thin text-gray-700 uppercase">{{ $t(`timetracking.week.sections.${section.key}`) }}</span>
                        <span class="block text-3xl font-medium" :class="{'text-red-600': section.key == 'invoicable' && section.hours !== '00h00', 'text-green-600': section.key == 'invoicable' && section.hours == '00h00'}">{{ section.hours }}</span>
                        <span class="block text-lg text-gray-800">{{ section.price | price }}</span>
                    </div>
                </div>
            </template>
        </div>

        <v-spa-panel :loading="true" v-if="loading"></v-spa-panel>

        <template v-else>
            <div class="mb-6" v-for="day in week" :key="day.day">
                <div class="flex justify-between mb-2">
                    <p :class="isToday(day.day) ? 'text-black font-medium' : 'text-gray-600'" v-text="displayDay(day.day)"></p>
                    <p :class="isToday(day.day) ? 'text-black font-medium' : 'text-gray-600'">
                        <span v-if="dayTotal(day.day)">{{ $t('timetracking.day_card.total') }}: {{ dayTotal(day.day) }}</span>
                    </p>
                </div>
                <v-spa-panel v-if="day.activities.length == 0">
                    <p class="text-center text-gray-400">{{ $t('timetracking.day_card.empty') }}</p>
                </v-spa-panel>
                <template v-else>
                    <transition-group name="time-activities" class="flex flex-col space-y-2">
                        <timetracker-line
                            :activity="activity"
                            v-for="activity in day.activities"
                            :key="activity.id"
                            :ref="activity.getLineRef()"
                        ></timetracker-line>
                    </transition-group>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
	import TimeActivitiesStatistics from '~spa/classes/TimeActivitiesStatistics';
	import Line from './Week/Line';
    import SubHeader from '~base/components/_Partials/SubHeader';

    export default {
        components: {
            SubHeader,
			'timetracker-line': Line
		},
        
		data() {
			return {
                before: null,
				loading: true,
				load_more: true,
				dates: [],
                activities: [],
			}
		},

        props: {
            getFilters: { type: Function },
        },

        computed: {
            week()
            {
                let week = [];

                for ( let i = 0; i < this.dates.length; i++ ) {
                    week.push({
                        day: this.dates[i],
                        activities: this.getActivitiesAtDate(this.dates[i])
                    });
                }

                return week;
            },
        },

		methods: {
            applyFilters()
            {
                this.getActivities();
            },

            isToday(day)
            {
                return window.moment(day).isSame(window.moment(), 'day');
            },

            getActivitiesAtDate(date)
            {
                return this.activities
                    .filter(a => this.$moment(a.starts_at).isSame(this.$moment(date), 'day'))
                    .sort((a, b) => new Date(b.starts_at) - new Date(a.starts_at));
            },

			displayDay(day)
			{
                if ( window.moment(day).isSame(window.moment(), 'day') ) {
			        return window.moment(day).calendar();
                }

                if ( window.moment(day).isSame(window.moment().subtract(1, 'day'), 'day') ) {
			        return `${window.moment(day).calendar()} (${window.moment(day).format('dddd DD MMMM YYYY')})`;
                }

				return window.moment(day).format('dddd DD MMMM YYYY').capitalize();
			},

            getWeekTotalSections()
            {
                return [
                    {
                        key: 'total',
                        hours: this.weekTotalHours(),
                        price: this.weekTotalPrice()
                    },
                    {
                        key: 'invoiced',
                        hours: this.weekTotalHoursInvoiced(),
                        price: this.weekTotalPriceInvoiced()
                    },
                    {
                        key: 'invoicable',
                        hours: this.weekTotalHoursInvoicable(),
                        price: this.weekTotalPriceInvoicable()
                    },
                ]
            },

            weekTotalHours(activities = this.activities)
            {
                return new TimeActivitiesStatistics(activities).hours();
            },

            weekTotalHoursInvoiced()
            {
                return this.weekTotalHours(this.activities.filter(a => a.invoiced_at));
            },

            weekTotalHoursInvoicable()
            {
                return this.weekTotalHours(this.activities.filter(a => ! a.invoiced_at));
            },

            weekTotalPrice(activities = this.activities)
            {
                return new TimeActivitiesStatistics(activities).price();
            },

            weekTotalPriceInvoiced()
            {
                return this.weekTotalPrice(this.activities.filter(a => a.invoiced_at));
            },

            weekTotalPriceInvoicable()
            {
                return this.weekTotalPrice(this.activities.filter(a => ! a.invoiced_at));
            },

			dayTotal(day)
			{
				let total = this.getActivitiesAtDate(day).map(a => a.getTimeDifferenceInSeconds()).reduce((a, b) => a + b, 0);

				return window.moment(day).seconds(total).format('HH:mm');
			},
            
			addActivity(activity)
			{
                this.activities.push(activity);
			},

			updateActivity(activity)
			{
                this.activities = this.activities.map(a => a.id !== activity.id ? a : activity);
                
                this.$nextTick(() => this.$refs[activity.getLineRef()][0].init());
            },

			deleteActivity(activity)
			{
                this.activities = this.activities.filter(a => a.id !== activity.id);
			},

			setDatesAndActivities(dates, activities)
			{
                this.dates = Object.values(dates).sort((a, b) => new Date(b) - new Date(a));
                this.activities = activities;
			},

			async getActivities(before = '')
			{
                if ( ! before && this.before ) {
                    before = this.before;
                }
                
                this.loading = true;
                let params = {...{before}, ...this.getFilters()};

				let {activities, dates, load_more} = await window.Planning.endpoints.timeActivity.week(params)

                this.setDatesAndActivities(dates, activities);
                
                this.before = this.dates[0];
                this.loading = false;
                this.load_more = load_more;

                this.$emit('refresh-url', {before: this.before});
			},

			getNextWeekActivities()
			{
				this.getActivities(
                    this.$moment(this.dates[0]).add(1, 'week').endOf('week').format('YYYY-MM-DD')
                );
			},

			getPreviousWeekActivities()
			{
				this.getActivities(
                    this.$moment(this.dates[0]).subtract(1, 'week').endOf('week').format('YYYY-MM-DD')
                );
			}
		},
        created() {
			this.getActivities(this.$route.query.before);
		}
	}
</script>