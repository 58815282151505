export default {
    
    async getReports(context, {start, end, groupBy, users = null, worksites = null})
    {
        return await context.dispatch('getAccountData', {
            url: `${window.url.api}/reports?start=${start}&end=${end}&groupBy=${groupBy}&worksites=${worksites}&users=${users}`
        });
    },

    async getUserWeekHoursPlanned(context, {start, end, activity_id, user})
    {
        return await context.dispatch('getAccountData', {
            url: `${window.url.api}/users/${user}/week-hours-planned?activity=${activity_id}&start=${start}&end=${end}`
        });
    }

}