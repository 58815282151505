import axios from 'axios';
import { trustup_pro } from "~spa/classes/TrustUpPro";

export default {

    getUser(context, headers = null)
    {
        return new Promise(function(resolve, reject) {
            axios.get(window.url.auth + "/api/user?app=timetracker", {withCredentials: true})
                .then((response) => {
                    resolve({user: response.data.user, token: response.data.token, apps: response.data.apps});
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    getApps(context)
    {
        return new Promise(function(resolve, reject) {
            axios.get(window.url.auth + "/api/apps", {withCredentials: true})
                .then((response) => {
                    console.log(response);
                    console.log(response.data.apps);
                    window.App.$spa.apps = response.data.apps;
                })
                .catch((error) => {
                    console.error(error);
                    reject(error.response.data);
                });
        });
    },

    logout(context)
    {
        return  trustup_pro.logout();
    }
}