<template>
    <v-spa-submenu-container position="right-0" ref="submenu" @hidden="updated">
        <template v-slot:default="slotProps">
            <div @click.prevent="slotProps.toggle()" :class="`${$spa.css.button.style.white_border.replace('px-8 py-3', '')} text-sm cursor-pointer flex items-stretch`">
                <div class="flex items-center w-full px-3 py-2 space-x-2 lg:px-4 lg:py-3">
                    <div class="w-auto">
                        <i class=" far fa-user" v-if="users.length == 0"></i>
                        <users-avatar-list v-else :users="usersModel"></users-avatar-list>
                    </div>
                    <span class="text-sm whitespace-nowrap">{{ value() }}</span>
                </div>
                <div class="flex items-center justify-center px-3 border-l border-gray-200">
                    <i class="text-sm text-gray-600 far" :class="$refs.submenu && $refs.submenu.isDisplayed ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                </div>
            </div>
        </template>
        <template v-slot:submenu>
            <div class="w-[260px] p-2">
                <span class="mb-4 block text-gray-800 text-[15px] font-medium">
                    {{ $t('filters.users.title') }}
                </span>
                <input v-model="query" :placeholder="$t('filters.worksites.placeholder')" type="text" class="w-full px-3 py-2 mb-2 text-gray-800 transition bg-gray-100 border border-none rounded outline-none hover:border-gray-200 focus:border-primary">
                <div class="overflow-y-auto max-h-40">
                    <a :class="userIsSelected(user) ? 'text-primary' : 'text-gray-700'" class="relative pr-12 flex items-center w-full p-[2px] text-sm rounded hover:bg-gray-100" href="#" @click.prevent="toggleUser(user)" v-for="user in queriedUsers" :key="user.id">
                        <div class="relative border-2 rounded-full" :class="userIsSelected(user) ? 'border-primary' : 'border-transparent'">
                            <div class="w-8 h-8 border-2 border-transparent rounded-full">
                                <figure class="w-full h-full">
                                    <img :src="user.avatar" :alt="user.getName()" class="object-cover w-full h-full rounded-full" />
                                </figure>
                            </div>
                            <div v-if="userIsSelected(user)" class="absolute right-0 bottom-0 transform translate-x-1/2 translate-y-[.15rem] h-4 w-4 rounded-full flex bg-primary items-center justify-center">
                                <i class="fas fa-check text-[.5rem] text-white"></i>
                            </div>
                        </div>
                        <span class="ml-3" :class="{'font-medium': userIsSelected(user)}">{{ user.getName() }}</span>
                        <div class="absolute top-0 bottom-0 right-0 flex items-center pr-2" v-if="userIsSelected(user) && ! multiple">
                            <a href="#" @click.prevent="unselectUser(user)">
                                <i class="text-gray-400 far fa-times hover:text-gray-600"></i>
                            </a>
                        </div>
                    </a>
                </div>
            </div>
        </template>
    </v-spa-submenu-container>
</template>

<script>
    import {xor} from 'lodash';
    import {mapState} from 'vuex';
    import UsersAvatarList from '~base/components/_Partials/UsersAvatarList.vue';

    export default {
        components: {
            UsersAvatarList
        },

        props: {
            initialValue: {
                type: Array,
                default: () => []
            },
            multiple: {
                type: Boolean,
                default: false
            }
        },
        
        data() {
            return {
                query: '',
                users: [],
                changed: false
            };
        },

        computed: {
            ...{
                queriedUsers() {
                    if ( ! this.query ) {
                        return this.$store.state.models.users;
                    }

                    return this.$store.state.models.users.filter(user => {
                        return user.getName().toLowerCase().includes(this.query.toLowerCase());
                    });
                },
                usersModel() {
                    return this.$store.state.models.users.filter(user => {
                        return this.users.includes(user.id);
                    });
                }
            }
        },

        methods: {
            getBaseValueWithoutUndefinedUsers()
            {
                return this.users.filter(id => {
                    return this.$store.state.models.users.find(user => user.id == id) !== undefined;
                });
            },
            
            containsUndefinedUsers()
            {
                if ( this.users.length == 0 ) {
                    return false;
                }

                return this.users > this.getBaseValueWithoutUndefinedUsers();
            },
            
            value()
            {
                if ( this.users.length == 0 ) {
                    return this.$t('filters.users.button');
                }

                if ( this.users.length == 1 ) {
                    return this.usersModel[0].getName();
                }

                return this.$t('filters.users.multiple', {count: this.users.length});
            },

            userIsSelected(user)
            {
                if ( ! this.users ) {
                    return false;
                }
                
                return this.users.some(id => id == user.id);
            },

            toggleUser(user)
            {
                this.changed = true;

                if ( this.multiple ) {
                    this.users = xor(this.getBaseValueWithoutUndefinedUsers(), [user.id]);
                } else {
                    this.users = [user.id];
                    this.$refs.submenu.hide();
                }
            },

            unselectUser(user)
            {
                this.changed = true;
                this.users = [];
                this.$refs.submenu.hide();
            },

            setUsers(users)
            {
                this.users = users;
            },

            updated()
            {
                console.log('updated', this.changed);
                if ( this.changed ) {
                    this.multiple
                        ? this.$emit('updated', this.users)
                        : this.$emit('updated', this.users[0]);
                }
                this.query = '';
            }
        },

        created()
        {
            this.setUsers(this.initialValue);
        }
    }
</script>
