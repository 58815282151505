import Base from './actions/base';
import Auth from './actions/auth';
import Accounts from './actions/accounts';
import Professionals from './actions/professionals';
import TimeTracking from './actions/timetracking';
import Users from './actions/users';
import Planning from '~spa/apps/trustup-pro-planning-js/Planning';

const actions = Object.assign(
    Base,
    Auth,
    Accounts,
    Professionals,
    TimeTracking,
    Users
);


/**
 * These are methods can be be used globally.
 * They can contain asynchronous operations.
 * They will commit a mutation instead of mutating the state directly.
 */
export default actions;