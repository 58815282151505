<template>
    <v-spa-layout-page data-component="timetracking-index" :content-padding="false" :use-container="false">
		<template slot="title">{{ $t('timetracking.header.title') }}</template>

        <template slot="title-button">
            <div class="flex items-center space-x-2">
                <v-spa-loading-button extra-class="hidden xl:block" :color="$spa.css.button.color.white_border" @clicked="refresh">
                    <i class="mr-1 far fa-sync" />
                    {{ $t('timetracking.header.buttons.refresh') }}
                </v-spa-loading-button>
                
                <!-- <filters ref="filters" slot="filters" @updated="updateFilters"></filters> -->

                <v-spa-loading-button @clicked="handleNewButton">
                    <i class="mr-1 far fa-plus"></i>
                    {{ $t('timetracking.header.buttons.add') }}
                </v-spa-loading-button>
                <v-spa-loading-button ref="startButton" :color="$spa.css.button.color.green" @clicked="handleStartButton">
                    <i class="mr-1 fas fa-play"></i>
                    {{ $t('timetracking.header.buttons.start') }}
                </v-spa-loading-button>
            </div>
        </template>
        
        <template slot="content">
            <sub-header
                :range="{
                    start: range.start.format('YYYY-MM-DD'),
                    end: range.end.format('YYYY-MM-DD')
                }"
                @load-week="loadWeek"
                @update-filters="updateFilters"
                v-if="range.start"
            ></sub-header>
            <div class="relative">
                <div class="relative py-12" v-if="loading">
                    <v-spa-loader></v-spa-loader>
                </div>
                <div data-component="timetracking-lines" v-else class="flex items-start px-6 pt-10 space-x-8 lg:px-12">
                    <div class="w-[300px] sticky top-[40px] flex flex-col p-6 space-y-6 bg-[#FAFBFC] border border-gray-300 rounded">
                        <h2 class="text-xl">{{ $t('timetracking.week.sections.title') }}</h2>
                        <div v-for="(section, index) in getWeekTotalSections()" :key="`section-${index}`">
                            <span class="block mb-1 text-xs font-thin text-gray-700 uppercase">{{ $t(`timetracking.week.sections.${section.key}`) }}</span>
                            <span class="block text-4xl font-medium" :class="{'text-red-600': section.key == 'invoicable' && section.hours !== '00h00', 'text-green-600': section.key == 'invoicable' && section.hours == '00h00'}" v-if="section.hours">{{ section.hours }}</span>
                            <span class="block" :class="section.hours ? 'text-lg text-gray-800' : 'text-4xl font-medium'">{{ section.price | price }}</span>
                        </div>
                    </div>

                    <div class="w-full">
                        <div class="mb-6" v-for="day in week" :key="day.day">
                            <div class="flex justify-between mb-2">
                                <p :class="isToday(day.day) ? 'text-black font-medium' : 'text-gray-600'" v-text="displayDay(day.day)"></p>
                                <p :class="isToday(day.day) ? 'text-black font-medium' : 'text-gray-600'">
                                    <span v-if="dayTotal(day.day)">{{ $t('timetracking.day_card.total') }}: {{ dayTotal(day.day) }}</span>
                                </p>
                            </div>
                            <v-spa-panel v-if="day.activities.length == 0">
                                <p class="text-center text-gray-400">{{ $t('timetracking.day_card.empty') }}</p>
                            </v-spa-panel>
                            <template v-else>
                                <transition-group name="time-activities" class="flex flex-col space-y-2">
                                    <time-activity
                                        :activity="activity"
                                        v-for="activity in day.activities"
                                        :key="`activity-${activity.id}`"
                                        :ref="activity.getLineRef()"
                                    ></time-activity>
                                </transition-group>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </v-spa-layout-page>
</template>

<script>
	import TimeActivitiesStatistics from '~spa/classes/TimeActivitiesStatistics';
    import SubHeader from '~base/components/_Partials/SubHeader';
    import Filters from '~base/components/_Partials/Filters';
	import Week from './Views/Week';
	import Table from './Views/Table';
	import Calendar from './Views/Calendar';
    import TimeActivity from '~spa/apps/trustup-pro-planning-js/components/TimeActivity/Item';

    export default {
        components: {
            SubHeader,
            TimeActivity
        },

		data() {
			return {
                range: {
                    start: null,
                    end: null
                },
                loading: true,
                dates: [],
                activities: [],
			}
		},

        computed: {
            week()
            {
                let week = [];

                for ( let i = 0; i < this.dates.length; i++ ) {
                    week.push({
                        day: this.dates[i],
                        activities: this.getActivitiesAtDate(this.dates[i])
                    });
                }

                return week;
            },
        },

        methods: {
            bootRange()
            {
                if ( this.$route.params.start ) {
                    this.range.start = window.moment(this.$route.params.start);
                }
                if ( this.$route.params.end ) {
                    this.range.end = window.moment(this.$route.params.end);
                }

                if ( ! this.range.start || ! this.range.end ) {
                    this.range.start = window.moment().startOf('week');
                    this.range.end = window.moment().endOf('week');
                }
            },

			async getActivities()
			{
                this.loading = true;
                let params = {...{before: this.range.end.format('YYYY-MM-DD')}, ...this.$store.state.filters};

				let {activities, dates} = await window.Planning.endpoints.timeActivity.week(params)

                this.dates = Object.values(dates).sort((a, b) => new Date(b) - new Date(a));
                this.activities = activities;
                
                this.loading = false;

                this.refreshUrl();
			},

            loadWeek({start, end})
            {
                this.range.start = window.moment(start);
                this.range.end = window.moment(end);
                this.getActivities();
            },

            refreshUrl(params = {})
            {
                params = {...params, ...this.$store.state.filters};
                // this.$route.query = {...this.$route.query, ...params};
                // this.$router.push({name: this.$route.name, query: params});
                this.$store.dispatch('replaceQueryInUrl', {url: this.$route.path, params: params});
            },

            refresh()
            {
                this.getActivities();
            },
            
            updateFilters()
            {
                this.getActivities();
            },
            
			handleNewButton()
			{
				this.$bus.$emit('planning:time-activity-form-modal:open');
			},

			async handleStartButton()
			{
				let data = {
					user_id: this.$store.state.user.id,
					title: this.$store.state.user.options.time_activity_default_title || this.$t('timetracking.activity.default_title'),
					starts_at: window.moment().format('YYYY-MM-DD HH:mm:ss')
				};

                this.$refs.startButton.load();
                await window.Planning.endpoints.timeActivity.store(data);
                this.$refs.startButton.stop();
			},

            addActivityLocally(activity)
            {
                this.activities.push(activity);
            },

            updateActivityLocally(activity)
            {
                this.activities = this.activities.map(a => a.id !== activity.id ? a : activity);
            },

            deleteActivityLocally(uuid)
            {
                this.activities = this.activities.filter(a => a.uuid !== uuid);
            },

            isToday(day)
            {
                return window.moment(day).isSame(window.moment(), 'day');
            },

            getActivitiesAtDate(date)
            {
                return this.activities
                    .filter(a => this.$moment(a.starts_at).isSame(this.$moment(date), 'day'))
                    .sort((a, b) => new Date(b.starts_at) - new Date(a.starts_at));
            },

			displayDay(day)
			{
                if ( window.moment(day).isSame(window.moment(), 'day') ) {
			        return window.moment(day).calendar();
                }

                if ( window.moment(day).isSame(window.moment().subtract(1, 'day'), 'day') ) {
			        return `${window.moment(day).calendar()} (${window.moment(day).format('dddd DD MMMM YYYY')})`;
                }

				return window.moment(day).format('dddd DD MMMM YYYY').capitalize();
			},

            getWeekTotalSections()
            {
                return [
                    {
                        key: 'total',
                        hours: this.weekTotalHours(),
                        price: this.weekTotalPrice()
                    },
                    {
                        key: 'costs',
                        hours: null,
                        price: this.weekTotalPriceCost()
                    },
                    {
                        key: 'invoiced',
                        hours: this.weekTotalHoursInvoiced(),
                        price: this.weekTotalPriceInvoiced()
                    },
                    {
                        key: 'invoicable',
                        hours: this.weekTotalHoursInvoicable(),
                        price: this.weekTotalPriceInvoicable()
                    },
                ]
            },

            weekTotalHours(activities = this.activities)
            {
                return new TimeActivitiesStatistics(activities).hours();
            },

            weekTotalHoursInvoiced()
            {
                return this.weekTotalHours(this.activities.filter(a => a.invoiced_at));
            },

            weekTotalHoursInvoicable()
            {
                return this.weekTotalHours(this.activities.filter(a => ! a.invoiced_at));
            },

            weekTotalPrice(activities = this.activities)
            {
                return new TimeActivitiesStatistics(activities).price();
            },

            weekTotalPriceCost(activities = this.activities)
            {
                return new TimeActivitiesStatistics(activities).cost();
            },

            weekTotalPriceInvoiced()
            {
                return this.weekTotalPrice(this.activities.filter(a => a.invoiced_at));
            },

            weekTotalPriceInvoicable()
            {
                return this.weekTotalPrice(this.activities.filter(a => ! a.invoiced_at));
            },

			dayTotal(day)
			{
				let total = this.getActivitiesAtDate(day).map(a => a.getTimeDifferenceInSeconds()).reduce((a, b) => a + b, 0);

				return window.moment(day).seconds(total).format('HH:mm');
			},
        },

        created() {
            this.bootRange();
            this.getActivities();
        },
        mounted() {
            this.refreshUrl();
        },
        beforeDestroy() {
            if ( this.activities ) {
                this.activities.destroy();
            }
            
            this.$store.commit('resetFilters');
        }
	}
</script>