
<template>
        <div class="flex items-center px-2" v-show="!loading">
            <div class="">
                <v-spa-loading-button
                    :class-name="getButtonClass('dayGridMonth') + ' rounded-tl rounded-bl'"
                    @clicked="changeView('dayGridMonth')" 
                >
                    <img src="/images/sidebar/month.svg" class="w-auto h-4">
                </v-spa-loading-button>
            </div>

            <div class="">
                <v-spa-loading-button
                    :class-name="getButtonClass('timeGridWeek')"
                    @clicked="changeView('timeGridWeek')" 
                >
                    <img src="/images/sidebar/week.svg" class="w-auto h-4">
                </v-spa-loading-button>
            </div>

            <div class="">
                <v-spa-loading-button
                    :class-name="getButtonClass('timeGridDay') + ' rounded-tr rounded-br'"
                    @clicked="changeView('timeGridDay')" 
                >
                    <img src="/images/sidebar/day.svg" class="w-auto h-4">
                </v-spa-loading-button>
            </div>
        </div>
</template>

<script>

import { mapState } from 'vuex';


export default {
    components: {
    },
    data() {
        return {
            button: 'px-3 relative flex items-center justify-center h-10 w-full bg-gray-200 outline-none transition-opacity duration-200',
            selected: 'opacity-100',
            notSelected: 'opacity-25 hover:opacity-100',
            loading: true
        }
    },
    methods: {
        closeResponsiveSidebar()
        {
            if ( this.$bus ) {
                this.$bus.$emit('spa:closeResponsiveSidebar');
            }

            return this;
        },
        changeView(viewName)
        {
            if ( this.$bus ) {
                this.$bus.$emit('fullCalendar:changeView', viewName);
            }

            return this.closeResponsiveSidebar();
        },
        getButtonClass(viewName)
        {
            return this.calendar.view == viewName ? this.selectedButton : this.notSelectedButton;
        }
    },
    computed: {
        ...mapState(['calendar']),
        selectedButton()
        {
            return `${this.button} ${this.selected}`;
        },
        notSelectedButton()
        {
            return `${this.button} ${this.notSelected}`;
        },
    },
    created()
    {
        this.$bus.$on('calendar:loaded', () => this.loading = false);
    }
}

</script>

<style>

</style>