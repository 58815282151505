<template>
    <div class="flex w-full">
        <div class="flex items-center">
            <div class="-ml-4 first:ml-0" v-for="user in users" :key="user.id" :data-tooltip="user.getDisplayName()" data-tooltip-location="top">
                <div :class="`${avatarSize} border-2 border-white rounded-full cursor-pointer`">
                    <figure class="w-full h-full">
                        <img :src="user.avatar" :alt="user.getName()" class="object-cover w-full h-full rounded-full">
                    </figure>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        users: {
            type: Array,
            required: true
        },
        avatarSize: {
            type: String,
            default: 'w-[24px] h-[24px]'
        },
    }
}
</script>