<template>
    <master-layout>
        <template slot="content">
            <v-spa-pages-accounts />
        </template>
    </master-layout>
</template>
​
<script>
import MasterLayout from '~base/components/Layout';

export default {
    components: { MasterLayout }
}
</script>