<template>
    <select-worksite
        :initial-value="worksites"
        :multiple="true"
        @updated="updateFilter"
    />
</template>

<script>
    import {mapState} from 'vuex';
    import SelectWorksite from '~base/components/_Partials/Selects/Worksite.vue';

    export default {
        components: {
            SelectWorksite
        },
        computed: {
            ...mapState({
                worksites: state => state.filters.worksites
            })
        },

        methods: {
            updateFilter(worksites) {
                console.log(worksites);
                this.$store.commit('setFiltersWorksites', worksites);
                this.$emit('updated');
            },
        },

        created()
        {
            if ( this.$route.query.worksites && this.$route.query.worksites !== 'null' && this.worksites == null ) {
                this.$store.commit('setFiltersWorksites', this.$route.query.worksites.split(',').map(id => parseInt(id)));
            }

            if ( this.worksites == null ) {
                this.$store.commit('setFiltersWorksites', []);
            }
        }
    }
</script>
