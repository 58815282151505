<template>
    <div class="relative flex-1 w-full" :class="{'overflow-x-scroll': ! loading}">
        <div class="relative py-24" v-if="loading">
            <v-spa-loader />
        </div>
        <table class="h-full min-w-full" v-if="! loading">
            <thead>
                <tr>
                    <th class="min-w-[200px] xl:min-w-[240px] p-4 bg-white"></th>
                    <th class="min-w-[140px] xl:min-w-[180px] p-4 text-xs font-normal text-center text-gray-800 bg-[#FAFAFC] relative" v-for="(date, index) in datesRange" :key="'heading-'+date.format('YYYY-MM-DD')">
                        <template v-if="index == 0">
                            <div class="absolute top-0 left-0 z-1 h-[30px] w-[30px] bg-white"></div>
                            <div class="absolute top-0 left-0 z-2 h-[30px] w-[30px] bg-[#FAFAFC] rounded-tl-full"></div>
                        </template>
                        <span class="relative block z-3 xl:hidden">
                            {{ date.format('D MMM YYYY') }}
                        </span>
                        <span class="relative hidden z-3 xl:block">
                            {{ date.format('dddd D MMM YYYY') }}
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, index) in filteredUsers" :key="`user-${user.id}`">
                    <td class="min-w-[200px] xl:min-w-[240px] p-4 bg-white border-b border-gray-200" :class="{'border-t': index == 0 }">
                        <div class="flex items-start h-full">
                            <div>
                                <div class="flex items-center mb-2">
                                    <img :src="user.avatar" class="w-8 h-8 mr-2 rounded-full">
                                    <span class="text-sm">{{ user.getName() }}</span>
                                </div>
                                <div>
                                    <span class="block text-sm text-gray-600">
                                        {{ $t('planning.user_total_hours', {hours: getUserTotalHours(user)}) }}
                                    </span>
                                    <!-- <span class="block text-sm text-gray-600">Total suivi de temps : {{ getUserTotalActivitiesHours(user) }} (t)</span>
                                    <span class="block text-xs text-gray-600">Total semaine disponible : {{ user.weekly_hours }}h (t)</span> -->
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="w-[140px] xl:w-[180px] p-2 bg-[#FAFAFC] border-t border-b border-r border-gray-200" v-for="(date, index) in datesRange" :key="'row-'+date.format('YYYY-MM-DD')">
                        <div class="flex flex-col h-full space-y-2">
                            <div
                                class="p-2 bg-white rounded shadow cursor-pointer hover:shadow-lg"
                                @click="calendarEvent.preview()"
                                @contextmenu.prevent="calendarEvent.contextMenu($event)"
                                v-for="calendarEvent in getUserCalendarEventsForDate(user, date)"
                                :key="calendarEvent.id"
                            >
                                <planning-grid-item :calendar-event="calendarEvent" />
                            </div>
                            <div class="flex items-center justify-center py-2">
                                <a href="#" @click.prevent="createCalendarEventForUserAtDate(user, date)">
                                    <i class="text-lg text-gray-400 far fa-plus-circle"></i>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    import VueContext from 'vue-context';
    import Item from './Grid/Item';

    export default {
        components: {
            VueContext,
            'planning-grid-item': Item
        },
        
        props: {
            range: {
                type: Object,
            },
            calendarEvents: {
                type: Array,
            },
            loading: {
                type: Boolean
            }
        },

        computed: {
            datesRange()
            {
                if ( ! this.range.start ) {
                    return [];
                }

                let diff = this.$moment(this.range.end).diff(this.$moment(this.range.start), 'day')
                let range = []
                for (let i = 0; i < diff; i++) {
                    range.push(this.$moment(this.range.start).add(i, 'day'));
                }
                range.push(this.$moment(this.range.end));
                return range
            },
            filteredUsers()
            {
                if ( this.$store.state.filters.users.length == 0 ) {
                    return this.$store.state.models.users;
                }

                return this.$store.state.models.users.filter(user => {
                    return this.$store.state.filters.users.includes(user.id);
                });
            }
        },

        methods: {
            getDefaultStartHour()
            {
                return 8; // This should use the user's weekly hours in the future.
            },
            getDefaultEndHour()
            {
                return 16; // This should use the user's weekly hours in the future.
            },
            getUserCalendarEventsForDate(user, date)
            {
                return this.calendarEvents.filter(calendarEvent => {
                    return calendarEvent.user_id === user.id && calendarEvent.getStartsAt().isSame(date, 'day');
                }).sort((a, b) => {
                    return a.getStartsAt().diff(b.getStartsAt());
                });
            },

            getUserTotalHours(user)
            {
                let total = this.calendarEvents.filter(calendarEvent => {
                    return calendarEvent.user_id === user.id && calendarEvent.accounted_for_weekly_hours;
                }).reduce((total, calendarEvent) => {
                    return total + calendarEvent.getTimeDifferenceInHours();
                }, 0);
                let [hours, decimalMinutes] = total.toFixed(2).split('.');
                return hours + 'h' + Math.round(decimalMinutes/100*60).toString().padStart(2, '0');
            },

            getUserTotalActivitiesHours(user)
            {
                return 0; // TODO
            },

            getCalendarEventHtml(calendarEvent)
            {
                return new CalendarWeekEventHtml(calendarEvent, {displayUser: false, displayLinkedAppName: false}).render();
            },

            createCalendarEventForUserAtDate(user, date)
            {
                let start = this.findLastCalendarEventForUserAtDate(user, date);
                let end = start.clone().set('hour', this.getDefaultEndHour()).startOf('hour');
                if ( end.isBefore(start) ) {
                    start = date.clone().startOf('day').set('hour', this.getDefaultStartHour());
                }

				this.$bus.$emit('planning:calendar-event-form-modal:open', {
                    form: {
                        user: user,
                        start: start,
                        end: end
                    }
                });
            },

            findLastCalendarEventForUserAtDate(user, date)
            {
                let lastCalendarEvent = this.getUserCalendarEventsForDate(user, date).sort((a, b) => {
                    return a.getStartsAt().diff(b.getStartsAt());
                }).pop();
                return lastCalendarEvent ? lastCalendarEvent.getEndsAt().clone().add(1, 'minute') : date.clone().startOf('day').set('hour', this.getDefaultStartHour());
            },
        }
    }
</script>
