<template>
    <div class="relative flex items-center">
        <template v-if="activity.ends_at">
            <v-spa-submenu-container position="right-0" ref="invoiceSubmenu">
                <template v-slot:default="slotProps">
                    <button class="px-2 ml-2 text-lg" :class="{'text-gray-700 hover:text-gray-900': ! activity.isInvoiced(), 'text-green-500 hover:text-green-700': activity.isInvoiced()}" @click.prevent.stop="slotProps.toggle()">
                        <i v-if="! activity.isInvoiced()" class="fal fa-file-invoice-dollar"></i>
                        <i v-if="activity.isInvoiced()" class="fal fa-check"></i>
                    </button>
                </template>
                <template v-slot:submenu>
                    <div :class="$spa.css.submenu.items">
                        <a target="_blank" :href="activity.getInvoiceUrl()" :class="$spa.css.submenu.item" v-if="activity.isInvoiced() && activity.external_invoice_uuid">
                            <img :src="`${$spa.TrustUpPro.url}/apps/invoicing/icon.svg`" class="w-3 h-3 mr-2">
                            <span>
                                {{ $t('timetracking.actions.open_invoice') }}
                            </span>
                        </a>
                        <a href="#" :class="$spa.css.submenu.item" @click.stop.prevent="openCreateInvoiceModal">
                            <img :src="`${$spa.TrustUpPro.url}/apps/invoicing/icon.svg`" class="w-3 h-3 mr-2">
                            <span v-if="! activity.external_invoice_uuid">
                                {{ $t('timetracking.actions.create_invoice') }}
                            </span>
                            <span v-if="activity.external_invoice_uuid">
                                {{ $t('timetracking.actions.create_new_invoice') }}
                            </span>
                        </a>
                        <a v-if="activity.isInvoiced()" href="#" :class="$spa.css.submenu.item" @click.stop.prevent="unmarkActivityAsInvoiced">
                            <i class="mr-2 fal fa-times"></i>
                            {{ $t('timetracking.actions.unmark_as_invoiced') }}
                        </a>
                        <a v-if="! activity.isInvoiced()" href="#" :class="$spa.css.submenu.item" @click.stop.prevent="markActivityAsInvoiced">
                            <i class="mr-2 fal fa-file-check"></i>
                            {{ $t('timetracking.actions.mark_as_invoiced') }}
                        </a>
                    </div>
                </template>
            </v-spa-submenu-container>
        </template>
        <v-spa-submenu-container position="right-0" ref="actionsSubmenu">
            <template v-slot:default="slotProps">
                <button class="px-2 ml-2 text-lg text-gray-700 hover:text-gray-900" @click.prevent.stop="slotProps.toggle()">
                    <i class="far fa-ellipsis-v"></i>
                </button>
            </template>
            <template v-slot:submenu>
                <div :class="$spa.css.submenu.items">
                    <time-activity-actions-items
                        :activity="activity" 
                        @action="$refs.actionsSubmenu.hide()"
                    />
                </div>
            </template>
        </v-spa-submenu-container>
    </div>
</template>

<script>
    import Form from '~spa/classes/Form';
    import TimeActivityActionsItems from '~spa/apps/trustup-pro-planning-js/components/TimeActivity/ItemActions';
    
    export default {
        components: {
            TimeActivityActionsItems,
        },
        props: {
            activity: {
                type: Object,
                required: true
            }
        },
        
        data() {
            return {
                form: null
            }
        },

        methods: {
            hideSubmenu() 
            {
                this.displaySubmenu = false;
            },

            async stopActivity()
            {
                this.activity.ends_at = window.moment().format('YYYY-MM-DD HH:mm:ss');

                await this.updateActivity();
                this.$emit('activity-stopped', this.activity);
            },

            markActivityAsInvoiced()
            {
                this.$refs.invoiceSubmenu.hide();
                
                let now = window.moment().format('YYYY-MM-DD HH:mm:ss');
                this.activity.invoiced_at = now;
                this.updateActivity();
            },

            unmarkActivityAsInvoiced()
            {
                this.$refs.invoiceSubmenu.hide();
                
                this.activity.invoiced_at = null;
                this.activity.external_invoice_uuid = null;
                this.updateActivity();
            },

            async updateActivity()
            {
                this.form.load();

                let activity = await window.Planning.endpoints.timeActivity.update({uuid: this.activity.uuid, data: this.activity.requestData()})
                this.form.stop();
            },

            displayComments()
            {
                this.$refs.actionsSubmenu.hide();
				this.$bus.$emit('activity-comments-modal:open', {
                    options: this.activity.trustup_io_messaging
                });
            },

            displayGeolocation()
            {
                this.$refs.actionsSubmenu.hide();
				this.$bus.$emit('activity-geolocation-modal:open', {activity: this.activity});
            },

            displayModal()
            {
                this.$refs.actionsSubmenu.hide();
                this.$bus.$emit('planning:time-activity-modal:open', {activity: this.activity});
            },

            displayModalForm()
            {
                this.$refs.actionsSubmenu.hide();
				this.$bus.$emit('planning:time-activity-form-modal:open', {activity: this.activity});
            },

            async duplicateActivity()
            {
                let activity = await window.Planning.endpoints.timeActivity.duplicate(this.activity.uuid)
                this.$refs.actionsSubmenu.hide();
            },

            deleteActivity()
            {
                this.$refs.actionsSubmenu.hide();

                window.Planning.endpoints.timeActivity.delete(this.activity.uuid);
            },

            openCreateInvoiceModal()
            {
                this.$bus.$emit('invoicing:invoice-form-modal:open', this.activity);
                this.$refs.invoiceSubmenu.hide();
            },
            
            onInvoiceCreation({activity, invoiced_at, invoice_uuid})
            {
                if ( activity.uuid !== this.activity.uuid ) {
                    return;
                }

                this.activity.invoiced_at = invoiced_at;
                this.activity.external_invoice_uuid = invoice_uuid;
                this.updateActivity();
            }
        },
        created()
        {
            this.form = new Form(this.activity.formData());
            this.$bus.$on('invoicing:invoice-form-modal:created', this.onInvoiceCreation);
        },
        beforeDestroy()
        {
            this.$bus.$off('invoicing:invoice-form-modal:created', this.onInvoiceCreation);
        }
    }
</script>
