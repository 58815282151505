<template>
    <div class="relative bg-[#FAFAFC]">
        
        <full-calendar ref="fullCalendar" :options="calendarOptions" v-if="calendarOptions" />
        
        <div v-if="loading" class="absolute inset-0 flex justify-center items-start z-20 mt-[30px] py-24">
            <div class="absolute inset-0 z-10 bg-white opacity-50"></div>
            <div class="w-[250px] rounded-full bg-white shadow p-6 z-20 h-auto">
                <div class="relative">
                    <v-spa-loader />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .planning-calendar .fc-media-screen {
        height: 600px;
    }
    @import '~base/components/_Partials/Calendar/CalendarStyle.scss';
    .fc-header-toolbar {
        @apply px-4 py-2;
    }
    .fc-col-header-cell.fc-day {
        // display: none; // ?
    }
    .fc-timegrid-axis {
        border: 0 !important;
    }
    .fc-col-header .fc-day-mon {
        border-left: 0 !important;
        position: relative;
    }
    .fc-col-header .fc-day-mon:before,
    .fc-col-header .fc-day-mon:after {
        content: '';
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0;
        top: 0;
    }
    .fc-col-header .fc-day-mon:before {
        background: white;
        z-index: 1;
    }
    .fc-col-header .fc-day-mon:after {
        background: #FAFAFC;
        z-index: 2;
        border-radius: 9999px 0 0 0;
    }
    .fc-timegrid-axis,
    .fc-timegrid-slot-label-cushion,
    .fc-timegrid-slot-label {
        background: white;
    }
    .fc-timegrid-slot-label-cushion {
        min-width: 100px;
    }
</style>

<script>
    import FullCalendar from '@fullcalendar/vue';
    export default {
        components: {
            FullCalendar
        },
        props: {
            calendarOptions: {
                type: Object
            },
            loading: {
                type: Boolean
            }
        }
    }
</script>