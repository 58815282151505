<template>
    <v-spa-layout-page data-component="teams-index">
		<template slot="title">{{ $t('team.header.title') }}</template>
        <div slot="content" class="flex flex-wrap -mx-2">
            <team-user :user="user" v-for="user in $store.state.models.users" :key="user.id"></team-user>
        </div>
    </v-spa-layout-page>
</template>

<script>
    import User from './_User';

    export default {
        components: {
            'team-user': User,
        },
        methods: {
            updateUser(user)
            {
                console.log('updating user', user);
                this.$store.state.models.users = this.$store.state.models.users.map(u => u.id !== user.id ? u : user);
            },
        },
        created() {
            this.$bus.$on('user-modal:saved', this.updateUser);
        },
        beforeDestroy() {
            this.$bus.$off('user-modal:saved', this.updateUser);
        }
    }
</script>