<template>
    <div>
        <v-spa-layout
            ref="layout"
            @toggle-sidebar="handleSidebar"
            @show-sidebar="handleSidebar"
            @hide-sidebar="handleSidebar"
            :display-responsive-menu-on-desktop="false"
            responsiveSidebarClass="p-6"
            responsiveMenuIcon="/spa/close-white.svg"
        >
            <!-- <template v-slot:header-next-to-title>
                <v-spa-layout-portal name="header-next-to-title"></v-spa-layout-portal>
            </template> -->
            <layout-responsive-sidebar ref="responsiveSidebar" slot="header-sidebar"></layout-responsive-sidebar>
            <layout-sidebar ref="sidebar" slot="sidebar" v-if="display.sidebar"></layout-sidebar>
            <layout-content ref="content" slot="content">
                <slot name="content"></slot>
            </layout-content>
        </v-spa-layout>

        <v-spa-layout-portal></v-spa-layout-portal>

        <invoicing-temp-components />
        <worksite-components />
        <planning-components />
    </div>
</template>

<script>
    import Sidebar from './_Layout/Sidebar';
    import ResponsiveSidebar from './_Layout/ResponsiveSidebar';
    import Content from './_Layout/Content';
    import TrustUpProIntegrations from './TrustUpPro/Integrations';

    import InvoicingTempComponents from '~spa/apps/trustup-pro-invoicing-temp-js/components/Components';
    import PlanningComponents from '~spa/apps/trustup-pro-planning-js/components/Components';
    import WorksiteComponents from '~spa/apps/trustup-pro-worksite-js/components/Components';

    export default {
        components: {
            'trustup-pro-integrations': TrustUpProIntegrations,
            'layout-responsive-sidebar': ResponsiveSidebar,
            'layout-sidebar': Sidebar,
            'layout-content': Content,
            InvoicingTempComponents,
            PlanningComponents,
            WorksiteComponents,
        },
        data() {
            return {
                display: {
                    sidebar: true
                }
            }
        },
        methods: {
            handleSidebar(status) {
                if ( ! this.$store.state.layout.sidebar ) {
                    this.display.sidebar = false;
                    return;
                }

                this.display.sidebar = status;
            }
        },
        mounted() {
            setInterval(function () {
                window.moment.locale('fr');
                this.$moment().locale('fr');
            }, 1000);
            if ( ! this.$store.state.layout.sidebar ) {
                this.$bus.$emit('spa:hideSidebar')
            }
        }
    }
</script>  