<template>
    <div class="account-base">
        <layout>
            <template slot="content">
                <div class="relative py-24" v-if="loading">
                    <v-spa-loader></v-spa-loader>
                </div>
                <div class="relative h-full" v-if="! loading && $store.state.account && $store.state.account.uuid">
                    <router-view :key="'account-' + $store.state.account.uuid + '-' + $store.state.account.updated_at + '-' + $route.fullPath"></router-view>
                </div>
            </template>
        </layout>

        <portal to="sidebar-bottom">
            <time-activities-in-progress v-if="! loading"></time-activities-in-progress>
        </portal>
    </div>
</template>

<script>

    import {mapState} from 'vuex';
    import Layout from '~base/components/Layout';
    import TimeActivitiesInProgress from '~base/components/_Partials/TimeActivitiesInProgress';
    
    export default {
        components: {
            'layout': Layout,
            'time-activities-in-progress': TimeActivitiesInProgress
        },
        data() {
            return {
                loading: true,
            }
        },
        methods: {
            async getUsers()
            {
                let users = await window.TrustUpPro.getUsers();
                this.$store.commit('setUsers', users);
            },

            async getWorksites()
            {
                try {
                    let {worksites} = await window.Worksite.endpoints.worksite.index({all: true, tasks: true});
                    this.$store.commit('setWorksites', worksites);
                } catch (e) {
                    console.error(e);
                }
            }
        },
        computed: {
            ...mapState(['account']),
        },
        
        async created() {
            await this.getUsers();
            await this.getWorksites();
            this.loading = false;
        }
    }
</script>
