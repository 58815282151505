<template>
    <div data-component="trustup-pro-modal" class="fixed inset-0 flex items-center justify-center" style="z-index: 999;" v-if="opened">
        <div class="bg-gray-900 opacity-75 fixed inset-0" @click.prevent="$bus.$emit('trustup-pro-close-modal')"></div>
        <div class="p-4 rounded bg-white shadow relative" style="min-width: 400px; min-height: 300px;">
            <div class="absolute top-0 right-0 mr-2 mt-2 p-2" @click.prevent="$bus.$emit('trustup-pro-close-modal')"><i class="fal fa-times" /></div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            opened: {
                type: Boolean,
                default: false
            }
        }
    }
</script>