import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
  
// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: 'be-fr',
	fallbackLocale: 'be-fr',
	messages: window.translations
});

export default i18n;