import axios from 'axios';

export default {

    getProfessionals(context)
    {
        return context.dispatch('getData', {
            url: `${window.url.trustup_api}/me/professionals`,
            key: 'professionals'
        });
    },

    getProfessional(context, id)
    {
        return context.dispatch('getData', {
            url: `${window.url.trustup_api}/me/professionals/${id}`,
            key: 'professional'
        });
    },

    // setProfessional(context, professional)
    // {
    //     context.commit('setProfessional', professional);
    //     context.dispatch('setVariableInTitle', {variable: 'professionalname', value: professional.company});
    // },

}