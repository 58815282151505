<template>
    <v-spa-submenu-container position="right-0" ref="submenu" @hidden="updated">
        <template v-slot:default="slotProps">
            <div @click.prevent="slotProps.toggle()" :class="`${$spa.css.button.style.white_border.replace('px-8 py-3', '')} text-sm cursor-pointer flex items-stretch`">
                <div class="flex items-center w-full px-3 py-2 space-x-2 lg:px-4 lg:py-3">
                    <img :src="`${$spa.TrustUpPro.url}/apps/worksite/icon.svg`" class="w-6 h-6 mr-2">
                    <span class="flex-1 text-sm whitespace-nowrap">{{ value() }}</span>
                </div>
                <div class="flex items-center justify-center px-3 border-l border-gray-200">
                    <i class="text-sm text-gray-600 far" :class="$refs.submenu && $refs.submenu.isDisplayed ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                </div>
            </div>
        </template>
        <template v-slot:submenu>
            <div class="w-[350px] p-2">
                <div class="flex items-center w-full mb-4">
                    <img :src="`${$spa.TrustUpPro.url}/apps/worksite/icon.svg`" class="w-6 h-6 mr-2">
                    <span class="text-base text-gray-800">
                        {{ $t('filters.worksites.title') }}
                    </span>
                </div>
                <input v-model="query" :placeholder="$t('filters.worksites.placeholder')" type="text" class="w-full px-3 py-2 mb-2 text-gray-800 transition bg-gray-100 border border-none rounded outline-none hover:border-gray-200 focus:border-primary">
                <div class="overflow-y-auto max-h-40">
                    <a href="#" :class="[$spa.css.submenu.item, 'relative', {'bg-slate-100 text-primary underline underline-offset-2': worksiteIsSelected(worksite)}]" v-for="worksite in queriedWorksites" :key="worksite.uuid" @click.prevent="toggleWorksite(worksite)">
                        <span class="flex-1 block mr-2 overflow-hidden overflow-ellipsis">{{ worksite.title }}</span>
                        <div class="absolute top-0 bottom-0 right-0 flex items-center pr-2" v-if="worksiteIsSelected(worksite) && ! multiple">
                            <a href="#" @click.prevent="unselectWorksite(worksite)">
                                <i class="text-gray-400 far fa-times hover:text-gray-600"></i>
                            </a>
                        </div>
                    </a>
                </div>
            </div>
        </template>
    </v-spa-submenu-container>
</template>

<script>
    import {xor, unionBy} from 'lodash';
    import {mapState} from 'vuex';

    export default {        
        data() {
            return {
                query: '',
                worksites: [],
                changed: false
            };
        },

        props: {
            initialValue: {
                type: Array,
                default: () => []
            },
            multiple: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            ...{
                queriedWorksites() {
                    if ( ! this.query ) {
                        return this.worksitesWithSelected;
                    }

                    return this.$store.state.models.worksites.filter(worksite => {
                        return worksite.title.toLowerCase().includes(this.query.toLowerCase());
                    }).slice(0, 3);
                },

                worksitesModel() {
                    return this.$store.state.models.worksites.filter(worksite => {
                        return this.worksites.includes(worksite.uuid);
                    });
                },

                /**
                 * Return a mix of the first three worksites and the currently selected worksites.
                 * The final array needs to be three items minimum, unless there are less than that available.
                 */
                worksitesWithSelected()
                {
                    if ( ! this.$store.state.models.worksites ) {
                        return [];
                    }

                    let worksites = this.$store.state.models.worksites.slice(0, 3)

                    if ( this.worksites.length == 0 ) {
                        return worksites;
                    }

                    let selectedWorksites = this.worksites.map(uuid => {
                        return this.$store.state.models.worksites.find(worksite => worksite.uuid == uuid);
                    });

                    if ( selectedWorksites.length >= 3 ) {
                        return selectedWorksites;
                    }

                    return unionBy(
                        selectedWorksites, worksites,
                        'uuid'
                    ).splice(0, 3);
                }
            }
        },

        methods: {
            getBaseValueWithoutUndefinedWorksites()
            {
                return this.worksites.filter(uuid => {
                    return this.$store.state.models.worksites.find(worksite => worksite.uuid == uuid) !== undefined;
                });
            },
            
            containsUndefinedWorksites()
            {
                if ( this.worksites.length == 0 ) {
                    return false;
                }

                return this.worksites > this.getBaseValueWithoutUndefinedWorksites();
            },
            
            value()
            {
                if ( this.worksites.length == 0 ) {
                    return this.$t('filters.worksites.button');
                }

                if ( this.worksites.length == 1 ) {
                    return this.worksitesModel[0].title;
                }
                
                return this.$t('filters.worksites.multiple', {count: this.worksites.length});
            },

            worksiteIsSelected(worksite)
            {
                if ( ! this.worksites ) {
                    return false;
                }
                
                return this.worksites.some(uuid => uuid == worksite.uuid);
            },

            toggleWorksite(worksite)
            {
                this.changed = true;

                if ( this.multiple ) {
                    this.worksites = xor(this.getBaseValueWithoutUndefinedWorksites(), [worksite.uuid]);
                } else {
                    this.worksites = [worksite.uuid];
                    this.$refs.submenu.hide();
                }
            },

            unselectWorksite(worksite)
            {
                this.changed = true;
                this.worksites = [];
                this.$refs.submenu.hide();
            },

            updated()
            {
                if ( this.changed ) {
                    this.multiple
                        ? this.$emit('updated', this.worksites)
                        : this.$emit('updated', this.worksites[0]);
                }
                this.query = '';
            },

            setWorksites(worksites)
            {
                this.worksites = worksites;
            },
        },

        created()
        {
            this.setWorksites(this.initialValue);
        }
    }
</script>
