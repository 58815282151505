<template>
    <div ref="element">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        trigger: {
            required: true,
            type: Boolean
        },
        //  Applied during whole leaving transition (basically duration)
        leaving: {
            required: true,
            type: String
        },
        leavingFrom: {
            required: true,
            type: String
        },
        leavingTo: {
            required: true,
            type: String
        },
        //  Applied during whole coming transition (basically duration)
        coming: {
            required: true,
            type: String
        },
        comingFrom: {
            required: true,
            type: String
        },
        comingTo: {
            required: true,
            type: String
        },
    },
    methods: {
        start()
        {
            const el = this.$refs.element;
            // Restarting animation from other side.
            el.addEventListener('transitionend', this.restart)
            el.className = `${this.leavingFrom} ${this.leaving}`;
            el.className = `${this.leavingTo} ${this.leaving}`;
        },
        restart(e)
        {
            // Firing for every property transitioned. Making sure it fires once.
            if (e.propertyName === 'opacity') {
                const el = this.$refs.element;
                // Removing listener to be able to end transition properly
                el.removeEventListener('transitionend', this.restart)
                el.className = this.comingFrom;
                // wait for browser to paint dom again (otherwise animation going backwards)
                // Doing it twice since chrome is bugging if once only
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        el.className = `${this.comingFrom} ${this.coming}`;
                        el.className = `${this.comingTo} ${this.coming}`; 
                    })
                });
            }
        },
        end(e)
        {
            // Making sure it fires once only
            if (e.propertyName === 'opacity') {
                const el = this.$refs.element;
                // Removing event and setting class to initial state.
                el.removeEventListener('transitionend', this.end)
                el.className = '';
            }
        }
    },
    watch: {
        // Starting translation every time property value changes
        trigger()
        {
            this.start();
        }
    }
}
</script>

<style>

</style>