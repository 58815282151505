<template>
    <select-user
        :initial-value="users"
        :multiple="true"
        @updated="updateFilter"
    />
</template>

<script>
    import {mapState} from 'vuex';
    import SelectUser from '~base/components/_Partials/Selects/User.vue';

    export default {
        components: {
            SelectUser
        },
        computed: {
            ...mapState({
                users: state => state.filters.users
            })
        },

        methods: {
            updateFilter(users) {
                console.log(users);
                this.$store.commit('setFiltersUsers', users);
                this.$emit('updated');
            },
        },

        created()
        {
            if ( this.$route.query.users && this.$route.query.users !== 'null' && this.users == null ) {
                this.$store.commit('setFiltersUsers', this.$route.query.users.split(',').map(id => parseInt(id)));
            }

            if ( this.users == null ) {
                this.$store.commit('setFiltersUsers', []);
            }
        }
    }
</script>
