<template>
    <div class="w-full relative h-full flex flex-col overflow-y-auto overflow-x-hidden pl-[10px]">
        <div class="w-[4px] top-0 bottom-0 left-0 rounded-full absolute" :style="`background-color: ${calendarEvent.event_type_style.border_color}`"></div>
        <div class="flex justify-between h-auto mb-1">
            <div class="flex items-center">
                <span class="text-[13px] text-black font-semibold mr-1">
                    {{ getTime() }}
                </span>
                <i v-if="calendarEvent.timetracking_status == 'in-progress'" class="text-xs text-gray-600 fas fa-stop-circle"></i>
                <i v-if="calendarEvent.timetracking_status == 'completed'" class="text-xs text-gray-600 fas fa-check-circle"></i>
            </div>
            <span class="text-[13px] text-gray-600 font-light">{{ getDuration() }}</span>
        </div>
        <div class="flex-1">
            <div class="mb-1">
                <span class="block text-[12px] leading-tight">{{ getTitle() }}</span>
            </div>
            <div class="flex items-center justify-between text-xs" v-for="(timeActivity, index) in calendarEvent.time_activities" :key="timeActivity.uuid">
                <div class="text-gray-600">
                    <i class="far fa-stopwatch"></i>
                    <span>
                        {{ timeActivity.starts_at | moment().format('HH:mm') }}
                        <span v-if="timeActivity.ends_at">
                            -
                            {{ timeActivity.ends_at | moment().format('HH:mm') }}
                        </span>
                    </span>
                </div>
                <div v-if="getDurationDifferenceWithTimeActivities() !== null && index == calendarEvent.time_activities.length - 1" :class="[hasSpentMoreTimeOnTimeActivitiesThanPlanned() ? 'text-green-600' : 'text-red-600']">
                    <span v-text="hasSpentMoreTimeOnTimeActivitiesThanPlanned() ? '+' : '-'"></span>
                    {{ getDurationDifferenceWithTimeActivitiesFormatted() }}
                </div>
            </div>
        </div>
        <!-- <div class="absolute bottom-0 right-0 flex items-center">
            <div class="relative">
                <div :data-tooltip="this.getUserName()" class="relative h-[20px] w-[20px] rounded-full bg-gray-300 bg-cover" :style="`background-image: url(${this.getUserAvatar()})`"></div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        calendarEvent: {
            type: Object,
            required: true
        }
    },
    methods: {

        getTime()
        {
            return `${this.calendarEvent.getStartsAt().format('HH:mm')} - ${this.calendarEvent.getEndsAt().format('HH:mm')}`;
        },
        
        getDuration()
        {
            return this.calendarEvent.getTimeDifference('HH:mm')
        },

        getTitle()
        {
            if ( this.isLinkedToWorksite() && this.calendarEvent.event_type == 'worksite' ) {
                return this.getWorksiteTitle();
            }

            return this.calendarEvent.title || '';
        },

        getWorksiteTitle()
        {
            if ( this.calendarEvent.getWorksite().address && this.calendarEvent.getWorksite().address.city ) {
                return `${this.calendarEvent.getWorksite().title} - ${this.calendarEvent.getWorksite().address.city}`;
            }
            return this.calendarEvent.getWorksite().title;
        },

        getUserName()
        {
            return this.calendarEvent.getUser()
                ? this.calendarEvent.getUser().getName()
                : this.$t('form.user.empty');
        },

        getUserAvatar()
        {
            return this.calendarEvent.getUser()
                ? this.calendarEvent.getUser().avatar
                : '';
        },

        isLinkedToWorksite()
        {
            return this.calendarEvent.getWorksite();
        },

        isLinkedToIntervention()
        {
            return false;
        },

        hasSpentMoreTimeOnTimeActivitiesThanPlanned()
        {
            return this.getDurationDifferenceWithTimeActivities() !== null && this.getDurationDifferenceWithTimeActivities() >= 0;
        },

        getTotalDurationOfTimeActivities()
        {
            return this.calendarEvent.time_activities.filter(a => a.isCompleted()).reduce((total, timeActivity) => {
                return total + timeActivity.getTimeDifferenceInSeconds();
            }, 0);
        },

        getDurationDifferenceWithTimeActivities()
        {
            return this.calendarEvent.time_activities.find(a => a.isCompleted())
                ? this.getTotalDurationOfTimeActivities() - this.calendarEvent.getTimeDifferenceInSeconds()
                : null;
        },

        getDurationDifferenceWithTimeActivitiesFormatted()
        {
            return window.moment().startOf('day').seconds(
                Math.abs(this.getDurationDifferenceWithTimeActivities())
            ).format('HH:mm', { trim: false });
        }
    }
}
</script>