<template>
    <div data-component="trustup-pro-integrations">
        <make-invoice></make-invoice>
    </div>
</template>

<script>

    import MakeInvoice from './MakeInvoice';

    export default {
        components: {
            MakeInvoice
        },
        created()
        {
        }
    }
</script>