<template>
    <div class="flex items-center space-x-2">
        <filter-by-users ref="users" @updated="update"></filter-by-users>
        <filter-by-worksites ref="worksites" @updated="update"></filter-by-worksites>
    </div>
</template>

<script>
    import Users from './Filters/Users';
    import Worksites from './Filters/Worksites';

    export default {
        components: {
            'filter-by-users': Users,
            'filter-by-worksites': Worksites
        },
        methods: {
            update()
            {
                this.$emit('updated');
            },
            get()
            {
                return {
                    users: this.$refs.users.selectedUsers,
                    worksites: this.$refs.worksites.selectedWorksites
                };
            }
        }
    }
</script>
