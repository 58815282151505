<template>
    <div class="flex justify-between px-6 pt-10 pb-8 lg:px-12">
        <div class="flex items-center space-x-4">
            <div class="space-x-2 lg:flex lg:items-center">
                <div class="flex items-center mb-2 space-x-2 lg:mb-0">
                    <div @click.prevent="loadPreviousWeek()" class="flex items-center justify-center w-8 h-8 text-gray-700 bg-white border border-gray-200 rounded-full cursor-pointer lg:w-10 lg:h-10 xl:w-12 xl:h-12 hover:bg-gray-200">
                        <i class="text-xs lg:text-sm far fa-chevron-left"></i>
                    </div>
                    <div @click.prevent="loadNextWeek()" class="flex items-center justify-center w-8 h-8 text-gray-700 bg-white border border-gray-200 rounded-full cursor-pointer lg:w-10 lg:h-10 xl:w-12 xl:h-12 hover:bg-gray-200">
                        <i class="text-xs lg:text-sm far fa-chevron-right"></i>
                    </div>
                </div>
                <div class="block text-sm text-gray-800 lg:hidden">
                    <span>{{ getStart().format('D MMM') }}</span>
                    <span> - </span>
                    <span>{{ getEnd().format('D MMM YYYY') }}</span>
                </div>
                <div class="hidden text-base text-gray-800 xl:text-lg lg:block">
                    <span>{{ getStart().format('D MMMM') }}</span>
                    <span> - </span>
                    <span>{{ getEnd().format('D MMMM YYYY') }}</span>
                </div>
            </div>
            <div class="hidden xl:block">
                <span class="inline-block px-6 py-2 text-sm font-normal text-gray-700 rounded bg-slate-100 px-R">
                    {{ $t('subheader.week_number', {number: getStart().week()}) }}
                </span>
            </div>
        </div>
        <div>
            <filters ref="filters" @updated="$emit('update-filters')"></filters>
        </div>
    </div>
</template>

<script>
    import Filters from '~base/components/_Partials/Filters';

    export default {
        components: {
            Filters
        },
        props: {
            range: {
                type: Object,
                required: true
            }
        },
        methods: {
            getStart()
            {
                return window.moment(this.range.start);
            },
            getEnd()
            {
                return window.moment(this.range.end);
            },
            loadPreviousWeek()
            {
                let start = this.getStart().subtract(1, 'week').startOf('week');
                this.$emit('load-week', {
                    start: start.format('YYYY-MM-DD'),
                    end: start.clone().endOf('week').format('YYYY-MM-DD')
                });
            },
            
            loadNextWeek()
            {
                let start = this.getStart().add(1, 'week').startOf('week');
                this.$emit('load-week', {
                    start: start.format('YYYY-MM-DD'),
                    end: start.clone().endOf('week').format('YYYY-MM-DD')
                });
            },
        }
    }
</script>