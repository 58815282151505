<template>
    <div data-component="timetracking-table">
        <div class="relative py-24" v-if="loading"><v-spa-loader></v-spa-loader></div>
        <template v-else>
            <table class="w-full">
                <thead>
                    <tr>
                        <th :class="$spa.css.table.th_first">Titre</th>
                        <th :class="$spa.css.table.th">Collaborateur</th>
                        <th :class="$spa.css.table.th">Date</th>
                        <th :class="$spa.css.table.th">Durée</th>
                        <th :class="$spa.css.table.th">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <timetracker-table-row
                        v-for="activity in activitiesSorted"
                        :activity="activity"
                        :key="activity.uuid"
                    ></timetracker-table-row>
                </tbody>
            </table>
            <v-spa-pagination
                :page="filters.page"
                :pagination="pagination"
                @click="getActivities"
                v-if="! loading"
            ></v-spa-pagination>
        </template>
    </div>
</template>

<script>
    import Row from './Table/Row';

    export default {
        components: {
            'timetracker-table-row': Row
        },
        
		data() {
			return {
				loading: true,
                activities: null,
                filters: {
                    page: 1,
                },
                pagination: null
			}
		},

        props: {
            getFilters: { type: Function },
        },

        computed: {
            activitiesSorted()
            {
                return ! this.activities
                    ? []
                    : this.activities.sort((a, b) => new Date(b.starts_at) - new Date(a.starts_at));
            }
        },

		methods: {
            applyFilters()
            {
                this.getActivities(); 
            },

			async getActivities(page = 1)
			{
                this.loading = true;
                this.filters.page = page;
                let params = {...this.filters, ...this.getFilters()};
                
				let {activities, pagination} = await window.Planning.endpoints.timeActivity.table(params);
                
                this.$emit('refresh-url', this.filters);
                this.activities = activities;
                this.pagination = pagination;
                this.loading = false;
			},

            addActivity(activity)
            {
                this.activities.unshift(activity);
            },

            updateActivity(activity)
            {
                this.activities = this.activities.map(a => a.id !== activity.id ? a : activity);
            },

            deleteActivity(activity)
            {
                this.activities = this.activities.filter(a => a.id !== activity.id);
            },

		},
		created() {
            if ( this.$route.query.page ) {
                this.filters.page = this.$route.query.page;
            }

            this.getActivities(this.filters.page);
		}
	}
</script>