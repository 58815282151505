<template>
    <modal :opened="opened">
        <v-spa-loader v-if="loading"></v-spa-loader>
        <div v-else>
            <div v-if="invoice">
                <p>Facture créée ou modifiée !</p>
                <div class="flex justify-center mt-8">
                    <button :class="$spa.css.button.style.default" @click.prevent="$bus.$emit('trustup-spa-modal-close')">Fermer</button>
                </div>
            </div>
            <div v-else>
                <h2 class="text-lg text-slate-800 mb-4">Sélectionner une facture existante, ou créer une nouvelle</h2>
                <div v-for="invoice in invoices" :key="invoice.uuid">
                    <p>{{ invoice.total }}€</p>
                </div>
                <div class="flex justify-center mt-8">
                    <button :class="$spa.css.button.style.default" @click.prevent="storeInvoice">Créer une nouvelle facture</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import { trustup_pro } from "~spa/classes/TrustUpPro";
    import Modal from './Modal';
    import ModalMixin from './mixins/Modal';
    export default {
        components: {
            Modal
        },
        data() {
            return {
                event: 'trustup-pro-make-invoice',
                loading: true,
                accounts: null,
                account: null,
                invoices: null,
                invoice: null,
                data: null,
            }
        },
        mixins: [ModalMixin],
        methods: {
            async onOpen(data)
            {
                this.data = data;
                console.log('MakeInvoice modal opened', data);

                console.log('Getting accounts');
                this.accounts = await trustup_pro.getAccounts('invoicing');
                await this.selectAccount(this.accounts[0]);
                this.loading = false;
            },

            async selectAccount(account)
            {
                console.log('Selecting account, getting invoices');
                this.account = account;
                this.invoices = await trustup_pro.getInvoices(this.accounts[0].uuid, {status: 'draft'});
            },

            async storeInvoice()
            {
                console.log('Storing invoice');
                this.loading = true;
                this.invoice = await trustup_pro.storeInvoice(this.accounts[0].uuid, {...{title: 'Automatic invoice from TimeTracker'}, ...this.data});
                this.loading = false;
            }
        }
    }
</script>