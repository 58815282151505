<template>
    <v-spa-layout-page data-component="planning-index" :content-padding="false" :use-container="false" content-class-name="flex flex-col">
		<template slot="title">{{ $t('planning.header.title') }}</template>

        <!-- <div slot="title-button">
            <filters ref="filters" @updated="updateFilters"></filters>
        </div> -->

        <template slot="tabs">
            <template v-for="availableView in availableViews">
                <a
                    href="#"
                    :class="[$spa.css.layout.page_tabs.tab, view == availableView ? $spa.css.layout.page_tabs.tab_active : '']" 
                    :key="availableView"
                    @click.prevent="setView(availableView)"
                >
                    {{ $t(`planning.views.${availableView}`) }}
                </a>
            </template>
        </template>
        
        <template slot="content">
            <sub-header
                :range="range"
                @load-week="loadWeek"
                @update-filters="updateFilters"
            ></sub-header>

            <div class="flex-1" id="full-calendar-container" v-if="calendarEvents">
                <component
                    ref="view"
                    :calendar-options="calendarOptions"
                    :loading="loading"
                    :range="range"
                    :calendar-events="calendarEvents.get()"
                    v-bind:is="`planning-${view}`"
                >
                </component>
            </div>
        </template>
    </v-spa-layout-page>
</template>

<script>

    import SubHeader from '~base/components/_Partials/SubHeader';
    import CalendarMixin from '~base/components/_Partials/Calendar/CalendarMixin';
    import Calendar from './_Calendar.vue';
    import Grid from './_Grid.vue';

    export default {
        components: {
            'planning-calendar': Calendar,
            'planning-grid': Grid,
            SubHeader,
        },
        mixins: [CalendarMixin],
        data() {
            return {
                availableViews: ['grid', 'calendar'],
                view: 'grid',
                loading: true,
                range: {
                    start: null,
                    end: null,
                },
                calendarEvents: null,
            }
        },
        methods: {
            bootRange()
            {
                if ( this.$route.params.start ) {
                    this.range.start = window.moment(this.$route.params.start);
                }
                if ( this.$route.params.end ) {
                    this.range.end = window.moment(this.$route.params.end);
                }

                if ( ! this.range.start || ! this.range.end ) {
                    this.range.start = window.moment().startOf('week');
                    this.range.end = window.moment().endOf('week');
                }
            },

            setView(view)
            {
                this.view = view;
            },

            updateFilters()
            {
                this.getCalendarEvents(this.range.start, this.range.end);
            },

            loadWeek(range)
            {
                if ( this.view == 'calendar' ) {
                    let api = this.$refs.view.$refs.fullCalendar.getApi();
                    api.gotoDate(range.start);
                    return;
                }

                this.range = range;
                this.getCalendarEvents(this.range.start, this.range.end);
            },

            getCustomCalendarOptions()
            {
                return {
                    allDaySlot: false,
                    editable: true,
                    selectable: true,
                    
                    datesSet: this.handleDatesSet,
                    dateClick: this.handleDateClick,
                    select: this.handleSelect,
                    eventClick: this.handleEventClick,
                    eventDrop: this.handleEventDrop,
                    eventResize: this.handleEventResize
                };
            },
            
			async getCalendarEvents(start = null, end = null)
			{
                if ( ! start ) {
                    start = this.range.start.format('YYYY-MM-DD');
                }
                if ( ! end ) {
                    end = this.range.end.format('YYYY-MM-DD');
                }

                this.loading = true;
                let params = {...{start, end}, ...this.$store.state.filters};

				let {events} = await window.Planning.endpoints.calendarEvent.index(params);
                this.calendarEvents = events;
                this.formatEvents(this.calendarEvents);
                this.$store.dispatch('replaceQueryInUrl', {url: this.$route.path, params: params});
                this.loading = false;
			},

            formatEvents(calendarEvents)
            {
                this.calendarOptions.events = calendarEvents.map((e) => e.toCalendarEvent());
            },

            getEventTimes(event)
            {
                return {starts_at: window.moment(event.startStr).format('YYYY-MM-DD HH:mm:ss'), ends_at: window.moment(event.endStr).format('YYYY-MM-DD HH:mm:ss')};
            },

            handleDatesSet(event)
            {
                let start = window.moment(event.startStr).format('YYYY-MM-DD');
                let end =  window.moment(event.endStr).format('YYYY-MM-DD');

                if ( this.range.start == start && this.range.end == end ) {
                    return;
                }

                this.range.start = start;
                this.range.end = end;

                this.getCalendarEvents(this.range.start, this.range.end);
            },

            handleDateClick(event)
            {
                // console.log('date clicked:', event);
            },

            handleSelect(event)
            {
				this.$bus.$emit('planning:calendar-event-form-modal:open', {
                    form: {
                        start: this.$moment(event.start),
                        end: this.$moment(event.end),
                    },
                    source: 'calendar'
                });
            },

            handleEventClick({event})
            {
				this.$bus.$emit('planning:calendar-event-modal:open', {calendarEvent: event.extendedProps.model});
            },

            handleEventDrop({event})
            {
                this.updateEvent(
                    event,
                    this.getEventTimes(event)
                );
            },

            handleEventResize({event})
            {
                this.updateEvent(
                    event,
                    this.getEventTimes(event)
                )
            },

            async updateEvent(event, data)
            {
                let calendarEvent = await window.Planning.endpoints.calendarEvent.update({uuid: event.extendedProps.model.uuid, data})
                this.updateCalendarEventLocally(calendarEvent);
            },

            addCalendarEventLocally(calendarEvent)
            {
                console.log('Add calendar event to calendar locally', calendarEvent);
                this.calendarOptions.events.push(calendarEvent.toCalendarEvent());
                this.calendarEvents.push(calendarEvent);
            },

            updateCalendarEventLocally(calendarEvent)
            {
                console.log('Update calendar event in the calendar locally:', calendarEvent);
                let event = calendarEvent.toCalendarEvent();
				this.$set(this.calendarOptions.events, this.calendarOptions.events.findIndex(e => e.id === event.id), event);
                this.calendarEvents = this.calendarEvents.map(e => e.id !== calendarEvent.id ? e : calendarEvent);
            },

            deleteCalendarEventLocally(uuid)
            {
                console.log('Delete calendar event from the calendar locally:', uuid);
				this.calendarOptions.events = this.calendarOptions.events.filter(e => e.extendedProps.model.uuid !== uuid);
                this.calendarEvents = this.calendarEvents.filter(a => a.uuid !== uuid);
            },
        },
		async created() {
            this.bootRange();
        },
        async mounted() {
            this.setUpCalendarOptions();
            await this.getCalendarEvents();
            this.$bus.$on('calendar-event:created', this.addCalendarEventLocally);
            this.$bus.$on('calendar-event:updated', this.updateCalendarEventLocally);
            this.$bus.$on('calendar-event:deleted', this.deleteCalendarEventLocally);
        },
        beforeDestroy() {
            this.$bus.$off('calendar-event:created', this.addCalendarEventLocally);
            this.$bus.$off('calendar-event:updated', this.updateCalendarEventLocally);
            this.$bus.$off('calendar-event:deleted', this.deleteCalendarEventLocally);

            this.$store.commit('resetFilters');
        }
    }
</script>
