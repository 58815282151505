<template>
    <div class="flex items-center">
        <transition
            enter-active-class="transition duration-200 transform"
            leave-active-class="transition duration-200 transform"
            enter-class="-translate-x-2 opacity-0"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="-translate-x-2 opacity-0"
        >
            <div class="hidden mr-2 overflow-hidden transition-all duration-200 transform sm:block" v-if="! loading">
                <button 
                    class="px-3 py-2 text-sm font-semibold transition duration-200 border rounded outline-none" 
                    :class="todayButtonClasses"
                    @click="getTodayCalendarPage"
                >{{ $t("pages.calendar.controls.today") }}</button>
            </div>
        </transition>
        
        <transition
            enter-active-class="transition duration-200 transform"
            leave-active-class="transition duration-200 transform"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="flex items-center flex-none" v-if="! loading">
                <div @click="getPreviousCalendarPage" class="flex items-center justify-center flex-none w-8 h-8 transition duration-200 bg-white rounded-full cursor-pointer group hover:bg-gray-200">
                    <i class="text-base text-gray-600 transition duration-200 fas fa-chevron-left group-hover:text-gray-800"></i>
                </div>
                <div @click="getNextCalendarPage" class="flex items-center justify-center flex-none w-8 h-8 transition duration-200 bg-white rounded-full cursor-pointer group hover:bg-gray-200">
                    <i class="text-base text-gray-600 transition duration-200 fas fa-chevron-right group-hover:text-gray-800"></i>
                </div>
            </div>
        </transition>
        <div class="overflow-hidden sm:ml-2">
            <vue-sliding
                :trigger="transitions.title.sliding"
                leaving="duration-75"
                leaving-from="transform translate-x-0 opacity-100"
                :leaving-to="`transform opacity-0 ${isDirectionRight ? 'translate-x-12' : '-translate-x-12'}`"
                coming="duration-75"
                :coming-from="`transform opacity-0 ${isDirectionRight ? '-translate-x-12' : 'translate-x-12'}`"
                coming-to="transform translate-x-0 opacity-100"
            >
                <div 
                    v-if="calendar.title"
                    class="text-sm leading-none text-gray-600 sm:text-lg"
                > 
                    {{ calendar.title }} 
                </div>
            </vue-sliding>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import VueSliding from "~base/transitions/Sliding.vue";

export default {
    components: {
        VueSliding
    },
    data() {
        return {
            loading: true,
            transitions: {
                title: {
                    direction: null,
                    sliding: false
                }
            }
        }
    },
    methods: {
        stop()
        {
            this.show = true;
        },
        getPreviousCalendarPage()
        {
            this.$bus.$emit('fullCalendar:previousPage');

            return this;
        },
        getNextCalendarPage()
        {
            this.$bus.$emit('fullCalendar:nextPage');

            return this;
        },
        transitionTitle(direction) {
            const { title } = this.transitions;
            title.direction = direction;
            title.sliding = ! title.sliding

            return this;
        },
        getTodayCalendarPage()
        {
            if (! this.calendar.isToday) {
                this.$bus.$emit('fullCalendar:today');
            }

            return this;
        },
    },
    computed: {
        ...mapState(['calendar']),
        isTodayButtonActive()
        {
            return (! this.loading) && (! this.calendar.isToday);
        },
        todayButtonClasses()
        {
            return this.isTodayButtonActive ? 'text-gray-600 border-gray-300 hover:bg-gray-200' : 'text-gray-400 border-gray-100 cursor-default';
        },
        isDirectionRight()
        {
            return this.transitions.title.direction === 'right';
        },
    },
    created() 
    {
        this.$bus.$on('calendar:loaded', () => this.loading = false);
        this.$bus.$on('calendar:transitionTitle', this.transitionTitle);
    }
}
</script>