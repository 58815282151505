<template>
    <div :class="containerClassName">
        <p :class="className" v-text="timeDifference"></p>
    </div>
</template>

<script>
import TimeActivity from '~spa/apps/trustup-pro-planning-js/models/TimeActivity';
export default {
    props: {
        start: {
            type: String,
            default: '',
        },
        end: {
            type: String,
            default: ''
        },
        inProgress: {
            type: Boolean,
            default: false
        },
        containerClassName: {
            type: String,
            default: 'w-[100px]',
        },
        className: {
            type: String,
            default: 'text-2xl font-semibold text-gray-800'
        }
    },
    data() {
        return {
            activity: null,
            timeDifference: null,
            timeDifferenceInterval: null
        }
    },
    watch: {
        start() {
            this.init();
        },
        end() {
            this.init();
        },
        inProgress() {
            this.init();
        }
    },
    methods: {
        getStart() {
            return this.start;
        },
        getEnd() {
            if ( this.end ) {
                return this.end;
            }
            
            if ( this.inProgress ) {
                return null;
            }

            return this.start;
        },
        init() {                        
            this.activity = new TimeActivity({
                starts_at: this.getStart(),
                ends_at: this.getEnd()
            });
            
            this.timeDifference = this.activity.getTimeDifference();
            this.timeDifferenceInterval = null;

            if ( ! this.activity.ends_at ) {
                this.timeDifferenceInterval = setInterval(() => this.timeDifference = this.activity.getTimeDifference(), 1000);
            }
        }
    },
    created() {
        this.init();
    }
}
</script>