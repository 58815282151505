<template>
    <div class="relative flex-auto h-full max-h-screen overflow-y-auto bg-body">    
        <slot></slot>
    </div>
</template>

<script>
    export default {
        //
    }
</script>