<template>
    <tr class="group">
        <td :class="`${$spa.css.table.td_first} group-hover:text-black`">
            <span class="block">{{ activity.title }}</span>
            <span class="block text-xs text-gray-600">{{ activity.worksite ? activity.worksite.title : $t('form.worksite.empty') }}</span>
        </td>
        <td :class="`${$spa.css.table.td} group-hover:text-black`">
            <div class="flex items-center">
                <span v-if="! activity.getUser()">/</span>
                <template v-if="activity.getUser()">
                    <div class="flex-shrink-0 w-6 h-6 bg-center bg-cover rounded-full" :style="`background-image: url(${activity.getUser().avatar})`"></div>
                    <span class="ml-2">{{ activity.getUser().getName() }}</span>
                </template>
            </div>
        </td>
        <td :class="`${$spa.css.table.td} group-hover:text-black`">
            <span class="block">{{ activity.getDay() | moment().format('dddd DD MMMM YYYY')}}</span>
            <span class="block text-xs text-gray-600" v-if="activity.getStartsAtTime() && activity.getEndsAtTime()">{{ activity.getStartsAtTime() }} - {{ activity.getEndsAtTime() }}</span>
            <span class="block text-xs text-gray-600" v-if="activity.getStartsAtTime() && ! activity.getEndsAtTime()">Commencée à {{ activity.getStartsAtTime() }}</span>
        </td>
        <td :class="`${$spa.css.table.td} group-hover:text-black`">
            <timetracker-activity-time-difference
                ref="timeDifference"
                :start="activity.starts_at"
                :end="activity.ends_at"
                :in-progress="activity.ends_at ? false : true"
                container-class-name=""
                class-name="text-lg font-semibold text-gray-800"
            ></timetracker-activity-time-difference>
        </td>
        <td :class="`${$spa.css.table.td_last} group-hover:text-black`">
            <timetracker-activity-actions
                :activity="activity"
                @activity-stopped="$refs.timeDifference.init()"
            ></timetracker-activity-actions>
        </td>
    </tr>
</template>

<script>
    import TimeDifference from '~base/components/TimeTracking/_Partials/TimeDifference';
    import ActivityActions from '~base/components/TimeTracking/Views/_Partials/ActivityActions';

    export default {
        components: {
            'timetracker-activity-time-difference': TimeDifference,
            'timetracker-activity-actions': ActivityActions
        },
        props: {
            activity: {
                type: Object,
                required: true
            }
        }
    }
</script>
