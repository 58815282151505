<template>
    <div class="flex flex-col flex-1 overflow-y-auto">
        <div class="flex-auto py-2">
            <template v-if="$store.state.account">
                <v-spa-layout-sidebar-link
                    section="planning"
                    :to="{name: 'account.planning.index', params: {account: $store.state.account.uuid}}"
                    icon="fal fa-calendar-alt"
                >
                    {{ $t('layout.sidebar.planning') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link
                    section="timetracking"
                    :to="{name: 'account.timetracking.index', params: {account: $store.state.account.uuid}}"
                    icon="fal fa-play-circle"
                >
                    {{ $t('layout.sidebar.timetracker') }}
                </v-spa-layout-sidebar-link>
                <!-- <v-spa-layout-sidebar-link
                    section="timesheet"
                    :to="{name: 'account.timesheet.index', params: {account: $store.state.account.uuid}}"
                    icon="fal fa-calendar-check"
                >
                    {{ $t('layout.sidebar.timesheet') }}
                </v-spa-layout-sidebar-link> -->
                <!-- <v-spa-layout-sidebar-link
                    section="team"
                    :to="{name: 'account.team.index', params: {account: $store.state.account.uuid}}"
                    icon="fal fa-user-friends"
                >
                    {{ $t('layout.sidebar.team') }}
                </v-spa-layout-sidebar-link> -->
                <v-spa-layout-sidebar-link
                    section="reports"
                    :to="{name: 'account.reports.index', params: {account: $store.state.account.uuid}}"
                    icon="fal fa-poll"
                >
                    {{ $t('layout.sidebar.reports') }}
                </v-spa-layout-sidebar-link>
            </template>
        </div>
        <div class="pt-6 pb-2" v-if="$store.state.account">
            <portal-target name="sidebar-bottom"></portal-target>
            <div class="flex justify-center mt-2" v-if="($store.state.account.chargebee_subscription_status == 'in_trial' || ($store.state.account.chargebee && $store.state.account.chargebee.is_trial)) && $store.state.user && $store.state.user.links.activate_subscription">
                <a :href="$store.state.user.links.activate_subscription" class="p-2 text-sm font-thin text-white bg-green-600 rounded hover:bg-green-500 sm:block">
                    <i class="lg:mr-2 far fa-check"></i>
                    <span class="hidden lg:inline-block">{{ $t('layout.sidebar.activate_account') }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                quickActions: false
            }
        },
        computed: {

        }
    }
</script>
