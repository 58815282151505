import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';
import deLocale from '@fullcalendar/core/locales/de';
import scrollGridPlugin from '@fullcalendar/scrollgrid';

import Vue from "vue";
import CalendarWeekEventHtml from "./CalendarWeekEventHtml";
var EventComponent = Vue.extend(CalendarWeekEventHtml);

let locales = {
    fr: frLocale,
    nl: nlLocale,
    de: deLocale
};

export default {
    data() {
        return {
            calendarOptions: null
        }
    },
    methods: {
        setUpCalendarOptions()
        {
            this.calendarOptions = {...this.getCustomCalendarOptions(), ...this.getDefaultCalendarOptions(), ...{events: []}};
        },

        getDefaultCalendarOptions()
        {
            return {
                plugins: [ timeGridPlugin, dayGridPlugin, interactionPlugin ],
                selectionColors: {
                    visible: 'rgba(43, 211, 245, 0.3)',
                    hidden: 'rgba(43, 211, 245, 0)',
                },
                transitionStart: false,
                transitionCallback: null,
                transitionDirection: 'right',
                headerToolbar: false,
                views: {
                    timeGridWeek: {
                        dayHeaderFormat: { weekday: 'short', day: 'numeric', omitCommas: true },
                        dayHeaderContent: this.renderTimeGridWeekHeader,
                        eventContent: this.renderEventInWeekView,
                        eventDidMount: this.eventDidMount,
                    },
                    timeGridDay: {
                        dayHeaderContent: this.renderTimeGridDayHeader,
                    },
                    dayGridMonth: {
                        titleFormat: { month: 'short', year: 'numeric' },
                        dayCellContent: this.renderDayInMonthView,
                        eventContent: this.renderEventInMonthView,
                    }
                },
                slotLabelInterval: '01:00',
                slotLabelFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                },
                nowIndicator: true,
                locale: this.$store.state.locale == 'en' ? 'en' : locales[this.$store.state.locale],
                eventColor: null,
                eventTextColor: null,
                handleWindowResize: true,
                navLinks: true,
                longPressDelay: 400,
                height: this.getAvailableHeightOrMinimum(),
                // dayMinWidth: 230,
                scrollTime: '06:00:00',
                firstDay: 1
            }
        },

        getAvailableHeight()
        {
            if ( document.getElementById('full-calendar-container') ) {
                return document.getElementById('full-calendar-container').clientHeight;
            }
            return window.innerHeight - 60 - 32;
        },

        getAvailableHeightOrMinimum()
        {
            return Math.max(600, this.getAvailableHeight());
        },
        
        getEventColor(event)
        {
            return event.textColor ? event.textColor : '#FFF';
        },

        getEventBackgroundColor(event)
        {
            return event.backgroundColor ? event.backgroundColor : '#999';
        },

        renderTimeGridWeekHeader({date, isToday})
        {
            let momentDate = window.moment(date);
            let circleClass = 'h-10 w-10 flex justify-center items-center rounded-full mb-2'
            circleClass += isToday ? ' bg-primary' : '';
            let dayClass = `text-2xl font-thin ${isToday ? "text-white" : "text-gray-600"}`
            return {
                html: `<div>
                            <span class="block uppercase text-2xs text-gray-500">${momentDate.format('ddd')}</span>
                            <span class="${circleClass}">
                                <span class="${dayClass}">${momentDate.format('D')}</span>
                            </span>
                        </div>`
            }
        },

        renderDayInMonthView({date, isToday})
        {
            
            if (isToday) {
                let link = document.createElement('a');
                link.classList = 'h-10 w-10 flex justify-center items-center cursor-pointer rounded-full bg-primary text-white font-bold';
                link.innerText = new Date().getDate();
                link.addEventListener('mousedown', e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.calendarGoToDate(date)
                        .changeCalendarView('timeGridDay');
                });
                
                return { domNodes: [ link ] }
            }
        },

        renderEventInMonthView(renderInfo)
        {
            const {event, isToday, timeText} = renderInfo;
            
            const setColorsAsDefault = node => {
                node.style.backgroundColor = this.getEventBackgroundColor(event);                
                node.style.borderColor = this.getEventBackgroundColor(event);
                node.style.color = this.getEventColor(event);
            };
           
            let title = document.createElement('div');
            title.classList = 'p-1 w-full truncate';
            let text = document.createElement('span');
            text.innerText = `${event.title}`;
            title.appendChild(text);
            setColorsAsDefault(title);

            return { domNodes: [ title ] };
        },

        renderEventInWeekView(renderInfo)
        {
            let component = new EventComponent({
                propsData: {
                    calendarEvent: renderInfo.event.extendedProps.model
                }
            })
            component.$mount();
            return {html: component.$el.outerHTML};
        },

        eventDidMount(renderInfo)
        {
            renderInfo.el.addEventListener('contextmenu', (event) => {
                event.preventDefault();
                this.$bus.$emit('planning:calendar-event-grid-context-menu:open', {calendarEvent: renderInfo.event.extendedProps.model, event});
                return false;
            }, false);

            let el = document.getElementById(renderInfo.event.extendedProps.model.html_id);
            el.parentElement.parentElement.style.setProperty('--calendar-event-bg-color', renderInfo.event.extendedProps.model.event_type_style.bg_color);
            el.parentElement.parentElement.style.setProperty('--calendar-event-border-color', renderInfo.event.extendedProps.model.event_type_style.border_color);
            el.parentElement.parentElement.style.setProperty('--calendar-event-text-color', renderInfo.event.extendedProps.model.event_type_style.text_color);
            const resizeObserver = new ResizeObserver((entries) => {
                entries.forEach(entry => {
                    if ( entry.contentRect.width < 100 ) {
                        el.classList.add('calendar-event-item--small-w');
                    } else {
                        el.classList.remove('calendar-event-item--small-w');
                    }
                    if ( entry.contentRect.height < 50 ) {
                        el.classList.add('calendar-event-item--small-h');
                    } else {
                        el.classList.remove('calendar-event-item--small-h');
                    }
                    if ( entry.contentRect.height < 100 ) {
                        el.classList.add('calendar-event-item--medium-h');
                    } else {
                        el.classList.remove('calendar-event-item--medium-h');
                    }
                });
            });

            resizeObserver.observe(el);
        }

    }
}