<template>
    <div data-component="teams-index-user" class="w-full px-2 md:w-1/2">
        <div class="flex items-center w-full px-6 py-4 mb-4 bg-white rounded shadow cursor-pointer border-l-[5px] border-slate-200 hover:bg-slate-100" :style="`border-color: ${user.event_background_color}`" @click.prevent="openUserModal">
            <div class="relative w-10 h-10 mr-4 bg-gray-300 bg-center bg-no-repeat bg-cover rounded-full" :data-tooltip="user.first_name" data-tooltip-location="right" :style="`background-image: url(${user.avatar});`"></div>
            <div class="flex-1">
                <p class="font-medium text-slate-900">{{ `${user.first_name} ${user.last_name}` }}</p>
            </div>
            <div class="flex justify-end">
                <i class="text-sm text-gray-600 far fa-pencil" />
            </div>
        </div>
    </div>
</template>

<script>
import {debounce} from 'lodash';
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            edit: {
                hourly_rate: false,
                hourly_cost: false,
                default_title: false
            }
        }
    },
    methods: {
        openUserModal()
        {
            console.log('openUserModal', this.user);
            this.$bus.$emit('user-modal:open', this.user);
        },
    },
    created() {
        // this.$store.commit('setSidebarSection', 'team');
    }
}
</script>