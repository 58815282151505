import axios from 'axios';
import User from '~spa/apps/trustup-pro-base-js/models/User';

export default {

    async getUsers(context)
    {
        let {users} = await context.dispatch('getAccountData', {
            url: `${window.url.api}/users`
        });

        return users.map((user) => new User(user));
    },

    async updateUserData(context, {id, data})
    {
        return await context.dispatch('updateAccountData', {
            url: `${window.url.api}/users/${id}/data`,
            data: data
        });
    }

}