export default {
    apiHeaders: state => {
        if ( ! state.token ) {
            return {};
        }

        return {
            'Content-Language': state.locale,
            'Authorization': state.token.token_type + ' ' + state.token.access_token
        };
    },

    appApiHeaders:(state, getters) => {
        let headers = {
            'Content-Language': state.locale
        };

        if ( state.token ) {
            headers['X-TrustUp-Authorization'] = state.token.token_type + ' ' + state.token.access_token
        }

        if ( window.App.$spa.user && window.App.$spa.user.jwt ) {
            headers['X-TrustUp-JWT'] = window.App.$spa.user.jwt;
        }

        return headers;
    },

    accountApiHeaders: (state, getters) => {
        let headers = getters.appApiHeaders;

        if ( state.account ) {
            headers['X-Account-UUID'] = state.account.uuid;
        }

        return headers;
    },

    taskData: (state, getters) => (task) => {
        let data = task.data();

        data.professional_authorization_key = state.user.default_professional.authorization_key;
        data.account_uuid = state.account.uuid;

        return data;
    },

    usersWithAdminRole: state => {
        return state.models.users.filter(user => user.role == 'super-admin' || user.role == 'admin');
    },

    usersWithWorkerRole: state => {
        return state.models.users.filter(user => user.role == 'user');
    }
}