import AccountsIndex from "./components/Accounts/Index.vue";
import AccountsBase from "./components/Accounts/Base";
import TimeTrackingIndex from "~base/components/TimeTracking/Index";
import TeamIndex from "~base/components/Team/Index";
import PlanningIndex from "~base/components/Planning/Index";
// import TimeSheetIndex from "~base/components/TimeSheet/Index";
import ReportsIndex from "~base/components/Reports/Index";
import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
import i18n from "./_i18n";

const generator = new RouteGenerator();

const routes = [

    generator.create({
        component: AccountsBase,
        redirect: { name: 'account.planning.index' },
        children: [
            
            generator.create({
                path: 'timetracking',
                name: 'account.timetracking.index',
                component: TimeTrackingIndex,
            })
            .title(i18n.t('timetracking.header.title'))
            .sidebar('timetracking')
            .get(),
            
            generator.create({
                path: 'planning',
                name: 'account.planning.index',
                component: PlanningIndex
            })
            .title(i18n.t('planning.header.title'))
            .sidebar('planning')
            .layout({container: false})
            .get(),
            
            // generator.create({
            //     path: 'timesheet',
            //     name: 'account.timesheet.index',
            //     component: TimeSheetIndex
            // })
            // .title(i18n.t('timesheet.header.title'))
            // .sidebar('timesheet')
            // .layout({container: false})
            // .get(),
            
            // generator.create({
            //     path: 'team',
            //     name: 'account.team.index',
            //     component: TeamIndex
            // })
            // .title(i18n.t('team.header.title'))
            // .sidebar('team')
            // .get(),
            
            generator.create({
                path: 'reports',
                name: 'account.reports.index',
                component: ReportsIndex
            })
            .title(i18n.t('reports.header.title'))
            .sidebar('reports')
            .get(),
        ]
    }).account().get(),

    generator.create()
        .homepage()
        .get(),
    
    generator.create({
        component: AccountsIndex,
    })
    .accounts()
    .title(i18n.t('pages.accounts.tab'))
    .get(),

];

export default routes;