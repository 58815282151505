import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import StoreCreator from '@deegital/trustup-spa-js-assets/store';

/**
 * Application store.
 * @type {import('@deegital/trustup-spa-js-assets/store/types').StoreInterface<import('@deegital/trustup-spa-js-assets/store/state/types').DefaultStateInterface, import('@deegital/trustup-spa-js-assets/store/types/mutations').DefaultMutations, import('@deegital/trustup-spa-js-assets/store/types/actions').DefaultActions>}
 */
const store = new StoreCreator({
    state,
    getters,
    actions,
    mutations
}).merge();

export default store;