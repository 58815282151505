<template>
    <div class="hidden px-3 lg:block">
        <div v-if="activities">
            <div class="flex items-center px-4 py-2 bg-gray-100 rounded" v-if="activities.get().length == 0">
                <i class="mr-4 text-gray-400 far fa-exclamation-triangle"></i>
                <p class="text-xs text-gray-600">{{ $t('activities_in_progress.empty') }}</p>
            </div>
            <div
                class="flex items-center mb-3 cursor-pointer"
                v-for="activity in activities.get()"
                :key="`activity-in-progress-${activity.uuid}`"
                @contextmenu.prevent="activity.contextMenu($event)"
                @click.prevent="activity.preview()"
            >
                <div v-if="activity.getUser()" class="flex-shrink-0 w-8 h-8 mr-2 bg-center bg-cover rounded-full" :style="`background-image: url(${activity.getUser().avatar})`" :data-tooltip="activity.getUser().getName()"></div>
                <div class="flex-1 mr-2" style="min-width: 0;">
                    <p class="text-sm text-gray-600 truncate">{{ activity.title }}</p>
                    <p class="text-xs text-gray-400 truncate" v-if="activity.getWorksite()">
                        {{ activity.getWorksite().title }}
                    </p>
                </div>
                <div class="flex items-center space-x-2">
                    <span class="text-gray-800">
                        <live-time-difference
                            container-class-name="w-auto"
                            class-name="text-gray-800"
                            format="HH:mm"
                            ref="timeDifference"
                            :activity="activity"
                        ></live-time-difference>
                    </span>
                    <div v-if="activity.status == 'paused'">
                        <i class="text-orange-600 far fa-pause-circle"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    import LiveTimeDifference from '@spa/apps/trustup-pro-planning-js/components/TimeActivity/_Partials/LiveTimeDifference';
    export default {
        components: {
            LiveTimeDifference
        },
        data() {
            return {
                activities: null,
                interval: null
            }
        },
        methods: {
            async getActivitiesInProgress(firstLoad = false)
            {
                if ( ! firstLoad && this.activities == null ) {
                    return;
                }
                
                let {activities} = await window.Planning.endpoints.timeActivity.inProgress();
                if ( this.activities ) {
                    this.activities.destroy();
                }
                this.activities = activities; 
            }
        },
        created() {
            this.getActivitiesInProgress(true);
            this.interval = setInterval(() => this.getActivitiesInProgress(), 30000);
        },
        beforeDestroy() {
            clearInterval(this.interval);
            if ( this.activities ) {
                this.activities.destroy();
            }
        }
    }
</script>
