require('~spa/_bootstrap');
require('~spa/_string-helpers');
require('~spa/_vue-bootstrap');

import SPA from '@deegital/trustup-spa-js-assets/setup';
import Vue from "vue";
import store from './store';
import i18n from './_i18n';
import router from './_router';

Vue.use(SPA);

/** Initializing package. */
SPA.init({
    app: process.env.MIX_TRUSTUP_APP_KEY,
    auth: process.env.MIX_TRUSTUP_AUTH_URL,
    flare: process.env.MIX_FLARE_KEY,
    store,
    i18n,
    router,
    env: process.env.MIX_APP_ENV
});

SPA.css.form.input_times = SPA.css.form.input.replace('px-3 py-2', 'p-2');
SPA.css.form.input_times_error = SPA.css.form.input.replace('px-3 py-2', 'p-2').replace('border-gray-300', 'border-red-600');