<template>
    <div class="p-2" data-component="timetracking-calendar">
        <full-calendar ref="fullCalendar" :options="calendarOptions" v-if="calendarOptions" />
    
        <div v-if="loading" class="absolute inset-0 flex justify-center items-start z-20 mt-[66px] py-24">
            <div class="absolute inset-0 z-10 bg-white opacity-50"></div>
            <div class="w-[250px] rounded-full bg-white shadow p-6 z-20 h-auto">
                <div class="relative">
                    <v-spa-loader />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '~base/components/_Partials/Calendar/CalendarStyle.scss';
    .fc-header-toolbar {
        @apply px-4 py-2;
    }
</style>

<script>

    import FullCalendar from '@fullcalendar/vue';
    import CalendarMixin from '~base/components/_Partials/Calendar/CalendarMixin';
    import CalendarControls from "~base/components/_Partials/Calendar/Controls.vue"
    import CalendarViews from "~base/components/_Partials/Calendar/Views.vue"

    export default {
        components: {
            CalendarControls,
            CalendarViews,
            FullCalendar
        },
        
        mixins: [CalendarMixin],

        data() {
            return {
                loading: true,
                range: {
                    start: null,
                    end: null,
                }
            }
        },

        props: {
            getFilters: { type: Function },
        },

        methods: {
            applyFilters()
            {
                this.getActivities(this.range.start, this.range.end);
            },
            
            getAvailableHeight()
            {
                // inner height - top bar - title height - panel padding - content padding
                return window.innerHeight - 60 - 90 - 32 - (16 * 3 * 2);
            },

            getCustomCalendarOptions()
            {
                return {
                    allDaySlot: false,
                    editable: true,
                    selectable: true,
                    
                    datesSet: this.handleDatesSet,
                    dateClick: this.handleDateClick,
                    select: this.handleSelect,
                    eventClick: this.handleEventClick,
                    eventDrop: this.handleEventDrop,
                    eventResize: this.handleEventResize,
                };
            },
            
			async getActivities(start, end)
			{
                this.loading = true;
                let params = {...{start, end}, ...this.getFilters()};

				await window.Planning.endpoints.timeActivity.calendar(params)
					.then(({activities}) => {
                        this.formatActivities(activities);
                        this.$emit('refresh-url', {start, end});
                        this.loading = false;
					});
			},

            formatActivities(activities)
            {
                this.calendarOptions.events = activities.map((a) =>a.toCalendarEvent());
            },

            getEventTimes(event)
            {
                return {starts_at: window.moment(event.startStr).format('YYYY-MM-DD HH:mm:ss'), ends_at: window.moment(event.endStr).format('YYYY-MM-DD HH:mm:ss')};
            },

            handleDatesSet(event)
            {
                let start = window.moment(event.startStr).format('YYYY-MM-DD');
                let end =  window.moment(event.endStr).format('YYYY-MM-DD');

                if ( this.range.start == start && this.range.end == end ) {
                    return;
                }

                this.range.start = start;
                this.range.end = end;

                this.getActivities(this.range.start, this.range.end);
            },

            handleDateClick(event)
            {
                // console.log('date clicked:', event);
            },

            handleSelect(event)
            {
				this.$bus.$emit('planning:time-activity-form-modal:open-from-calendar', {
                    start: this.$moment(event.start),
                    end: this.$moment(event.end)
                });
            },

            handleEventClick({event})
            {
				this.$bus.$emit('planning:time-activity-form-modal:open', {activity: event.extendedProps.model});
            },

            handleEventDrop({event})
            {
                this.updateEvent(
                    event,
                    this.getEventTimes(event)
                );
            },

            handleEventResize({event})
            {
                this.updateEvent(
                    event,
                    this.getEventTimes(event)
                )
            },

            async updateEvent(event, data)
            {
                let activity = await window.Planning.endpoints.timeActivity.update({uuid: event.extendedProps.model.uuid, data})
                event.setExtendedProp('model', activity);
            },

            addActivity(activity)
            {
                this.calendarOptions.events.push(activity.toCalendarEvent());
            },

            updateActivity(activity)
            {
                let event = activity.toCalendarEvent();
				this.$set(this.calendarOptions.events, this.calendarOptions.events.findIndex(e => e.id === event.id), event);
            },

            deleteActivity(activity)
            {
                let event = activity.toCalendarEvent();
				this.calendarOptions.events = this.calendarOptions.events.filter(e => e.id !== event.id);
            },
        },
		created() {
            this.setUpCalendarOptions();
        }
    }
</script>
