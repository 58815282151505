export default {
    data() {
        return {
            opened: false
        }
    },
    watch: {
        opened(opened)
        {
            document.body.style.overflow = opened ? "hidden" : "auto";
        }
    },
    methods: {
        openModal(data = null)
        {
            this.opened = true;
            
            if ( typeof this.onOpen !== "undefined" ) {
                this.onOpen(data);
            }
        },

        closeModal(data = null)
        {
            this.opened = false;
            
            if ( typeof this.onClose !== "undefined" ) {
                this.onClose(data);
            }
        }
    },
    created() 
    {
        this.$bus.$on('trustup-pro-open-modal', this.openModal);
        this.$bus.$on('trustup-pro-close-modal', this.closeModal);

        if ( this.event ) {
            this.$bus.$on(this.event, this.openModal);
        }
    }
}