<template>
    <div
        class="cursor-pointer"
        @contextmenu.prevent="$bus.$emit('planning:time-activity-context-menu:open', {activity, event: $event})"
        @click.prevent="$bus.$emit('planning:time-activity-modal:open', {activity})"
    >
        <v-spa-panel
            class="flex items-center space-x-4 bg-white"
        >
            <div
                class="w-10 h-10 bg-gray-200 bg-center bg-cover rounded-full"
                :style="activity.getUser() ? `background-image: url(${activity.getUser().avatar})` : ''"
            ></div>
            <div class="flex-1 leading-tight">
                <span class="block text-sm font-medium text-gray-800">{{ activity.title }}</span>
                <span class="block font-medium" v-if="activity.worksitePhase">{{ activity.worksitePhase.title }}</span>
                <span class="block text-xs text-gray-700" v-if="activity.worksite">{{ activity.worksite.title }}</span>
            </div>
            <div>
                <span class="block text-gray-600">{{ activity.getStartsAt().format('HH:mm') }} - {{ activity.getEndsAt().format('HH:mm') }}</span>
                <span class="block text-lg">
                    <time-difference
                        container-class-name="w-auto"
                        ref="timeDifference"
                        :start="activity.starts_at"
                        :end="activity.ends_at"
                        :in-progress="activity.ends_at == null"
                    ></time-difference>
                </span>
            </div>
            <div class="w-[150px] flex items-center justify-end relative">
                <timetracker-activity-actions
                    :activity="activity"
                ></timetracker-activity-actions>
            </div>
        </v-spa-panel>
    </div>
</template>

<script>
    import VueContext from 'vue-context';
    import ActivityActions from '~base/components/TimeTracking/Views/_Partials/ActivityActions';
    import TimeDifference from '~base/components/TimeTracking/_Partials/TimeDifference';

    export default {
        components: {
            VueContext,
            'timetracker-activity-actions': ActivityActions,
            TimeDifference
        },
        props: {
            activity: {
                required: true,
                type: Object
            }
        },
        // watch: {
        //     activity() {
        //         this.$nextTick(() => this.$refs.timeDifference.init());
        //     }
        // }
    }
</script>
