import Vue from 'vue';

require("./_spa");
require("./packages");

window.moment.updateLocale('en', {
    week: {
        dow: 1
    },
    calendar : {
        lastDay : '[Yesterday]',
        sameDay : '[Today]',
        nextDay : '[Tomorrow]'
    }
});

window.moment.updateLocale('fr', {
    calendar : {
        lastDay : '[Hier]',
        sameDay : '[Aujourd\'hui]',
        nextDay : '[Demain]'
    }
});

import App from './components/App';
import router from './_router';
import i18n from './_i18n';
import store from './store';

window.App = new Vue({
    el: '#app',
    template: '<App/>',
    components: {
        App,
    },
    i18n,
    store,
    router,
    created() {
        window.addEventListener('online', (event) => {
            window.Toasteo.success(
                this.$t('offline_mode.online')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });
        window.addEventListener('offline', (event) => {
            window.Toasteo.warning(
                this.$t('offline_mode.offline')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });
    }
}).$mount('#app');

window.timeToHoursAndMinutes = (time) => {
    if ( typeof time === 'number' ) {
        time = time.toString();
    }

    let [hours, minutes] = time.split('.');
    
    if ( typeof minutes == 'undefined' ) {
        minutes = '0';
    } else {
        minutes = String(minutes).padEnd(2, '0');
    }

    minutes = minutes.substring(0, 2);
    
    minutes = Math.round((minutes / 100 * 60));
    minutes = String(minutes).padStart(2, '0');

    return hours + 'h' + minutes;
}

window.hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

window.hex2hsl = (H, saturation = null, lightness = null) => {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (H.length == 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
    } else if (H.length == 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r,g,b),
        cmax = Math.max(r,g,b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta == 0)
        h = 0;
    else if (cmax == r)
        h = ((g - b) / delta) % 6;
    else if (cmax == g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
        h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    if ( saturation ) {
        s = saturation;
    }

    if ( lightness ) {
        l = lightness;
    }

    return "hsl(" + h + "," + s + "%," + l + "%)";
}

window.hex2pastel = (H) => {
    return window.hex2hsl(H, null, 93);
}

window.EXIF = require('exif-js');

import Hermite_class from './vendor/hermite.js';
window.HERMITE = new Hermite_class();

setInterval(function () {
    if ( window.moment ) {
        window.moment.locale('fr');
    }
    if ( window.App.$moment() ) {
        window.App.$moment().locale('fr');
    }
}, 1000);