<template>
    <div :id="calendarEvent.html_id" class="relative flex flex-col w-full h-full overflow-hidden">
        <div class="flex items-center justify-between h-auto mb-1 hide-on-sm-h">
            <div class="flex items-center">
                <span class="text-[14px] font-semibold mr-1">
                    {{ getTime() }}
                </span>
                <i class="text-xs fas fa-stop-circle" v-if="calendarEvent.timetracking_status == 'in-progress'"></i>
                <i class="text-xs fas fa-check-circle" v-if="calendarEvent.timetracking_status == 'completed'"></i>
            </div>
            <span class="text-[12px] hide-on-sm-w">
                <i class="far fa-clock"></i>
                {{ getDuration() }}
            </span>
        </div>
        <div class="flex-1 wow">
            <div class="mb-2">
                <span class="block text-[13px] leading-tight">{{ getTitle() }}</span>
            </div>
        </div>
        <div class="absolute bottom-0 right-0 flex items-center hide-on-md-h" v-if="calendarEvent.user">
            <div class="relative">
                <div :data-tooltip="getUserName()" class="relative h-[20px] w-[20px] rounded-full bg-gray-300 bg-cover" :style="`background-image: url(${getUserAvatar()})`"></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .calendar-event-item--small-h .hide-on-sm-h {
        display: none;
    }
    .calendar-event-item--medium-h .hide-on-md-h {
        display: none;
    }
    .calendar-event-item--small-w .hide-on-sm-w {
        display: none;
    }
    .calendar-event-item--small-w .text-\[14px\] {
        font-size: 12px;
    }
    .calendar-event-item--small-w .text-\[13px\] {
        font-size: 11px;
    }
</style>

<script>
export default {
    data() {
        return {
            id: null
        }
    },
    props: {
        calendarEvent: {
            type: Object,
            required: true
        }
    },
    methods: {

        getTime()
        {
            return `${this.calendarEvent.getStartsAt().format('HH:mm')} - ${this.calendarEvent.getEndsAt().format('HH:mm')}`;
        },
        
        getDuration()
        {
            return this.calendarEvent.getTimeDifference('HH:mm')
        },

        getTitle()
        {
            if ( this.isLinkedToWorksite() ) {
                return this.getWorksiteTitle();
            }

            return this.calendarEvent.title || '';
        },

        getWorksiteTitle()
        {
            if ( this.calendarEvent.getWorksite().address && this.calendarEvent.getWorksite().address.city ) {
                return `${this.calendarEvent.getWorksite().title} - ${this.calendarEvent.getWorksite().address.city}`;
            }
            return this.calendarEvent.getWorksite().title;
        },

        getColor()
        {
            if ( this.isLinkedToWorksite() ) {
                return '#10A4D3';
            }

            return '#FF4444';
        },

        isLinkedToWorksite()
        {
            return this.calendarEvent.getWorksite();
        },

        isLinkedToIntervention()
        {
            return false;
        },

        getUserName()
        {
            return this.calendarEvent.getUser()
                ? this.calendarEvent.getUser().getName()
                : this.$t('form.user.empty');
        },

        getUserAvatar()
        {
            return this.calendarEvent.getUser()
                ? this.calendarEvent.getUser().avatar
                : '';
        },
    }
}
</script>